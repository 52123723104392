const Text = {
    coming_soon: "곧 출시 예정입니다.",
    preparing: "준비 중입니다.",
    try: "나중에 다시 시도해주세요.",
    wait: "잠시만 기다려주세요.",
    password_error: "비밀번호가 올바르지 않습니다.",
    password_different: "비밀번호가 일치하지 않습니다.",
    password_enter: "비밀번호를 입력해주세요.",
    platform_id_exists: "이메일이 이미 등록되어 있습니다. 다른 로그인 방식을 사용해주세요.",
    different_login: "다른 로그인 방식으로 시도해주세요.",
    different_email: "이미 등록된 이메일입니다. 해당 이메일로 로그인해주세요.",
    different_google: "구글 아이디가 이미 등록되어 있습니다. 해당 아이디로 로그인해주세요.",
    different_apple: "애플 아이디가 이미 등록되어 있습니다. 해당 아이디로 로그인해주세요.",
    app_signup: "앱에서 회원가입이 가능합니다.",
    id_check_error: "ID 체크 오류입니다.",
    email_code_check: "이메일을 확인하고 코드를 입력해주세요.",
    email_format: "이메일 형식이 올바르지 않습니다.",
    email_format2: "E-mail 형식이 올바르지 않습니다.",
    email_send_failed: "이메일 전송에 실패했습니다.",
    email_success: "전송이 완료되었습니다! 관심을 가져주셔서 감사합니다.",
    email_try: "모든 데이터를 입력한 후 다시 시도해주세요.",
    no_login: "로그인이 필요합니다.",
    no_member: "회원 정보가 없습니다.",
    no_password: "비밀번호를 입력해주세요.",
    no_rate: "서버에 문제가 발생했습니다.",
    no_check: "체크해주세요.",
    no_gpoint: "G 포인트는 0보다 커야합니다.",
    no_balance: "잔액이 부족합니다.",
    no_percentage: "비율을 설정해주세요.",
    no_game_data: "게임 데이터가 없습니다. 게임을 플레이한 후 다시 시도해주세요.",
    not_code: "잘못된 코드입니다.",
    not_email_format: "올바른 이메일 형식이 아닙니다.",
    duplication_nick: "닉네임이 중복되었습니다.",
    char_limit: "문자 제한이 있습니다.",
    connect_wallet: "지갑 연결이 필요합니다.",
    change_binance: "또는 Binance Chain으로 네트워크를 변경하세요.",
    min_val: "최소 교환 금액은",
    max_val: "잔액보다 높은 수를 입력해주세요.",
    gas_success : "Gas 충전이 완료되었습니다. 거래전송 시간 때문에 충전에 시간이 필요합니다.",
    payment_success : "결제가 완료되었습니다. 거래전송 시간 때문에 충전에 시간이 필요합니다.",
    payment_success2 : "결제가 완료되었습니다.",
    payment_failed : "거래에 문제가 있습니다. 다시 시도해주세요.",
    payment_bcl : "결제는 BCL 앱에서 가능합니다.",
    payment_after : "거래가 이미 처리되었습니다. 새로고침 후 다시 시도해주세요.",
    payment_one_day : '하루에 한 번만 sending이 가능합니다. ',
}
export default Text;