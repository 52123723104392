import React from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import Alert from '../../utils/alert';

const addList = (history) => {
    const dom = document.querySelector('#statementList');
    var listChild, listChildP;
    for(var key in history){
        var list = document.createElement("div");
        list.className = 'box boundary';

        listChild = document.createElement("div");
        list.appendChild(listChild);
        listChildP = document.createElement("p");
        listChildP.innerText = 'G.point';
        listChild.appendChild(listChildP);
        listChildP = document.createElement("p");
        listChildP.className =  history[key]['txType'] === '0' ?'plus':'minus';
        listChildP.innerText = Number(history[key]['amount']).toLocaleString();
        listChild.appendChild(listChildP);
        
        listChild = document.createElement("div");
        list.appendChild(listChild);
        listChildP = document.createElement("p");
        listChildP.innerText = history[key]['gameType'];
        listChild.appendChild(listChildP);
        listChildP = document.createElement("p");
        listChildP.className =  history[key]['txType'] === '1' ?'plus':'minus';
        listChildP.innerText = (Number(history[key]['gameAmount']))?Number(history[key]['gameAmount']).toLocaleString():'';
        listChild.appendChild(listChildP);
        listChildP = document.createElement("p");
        listChildP.innerText = history[key]['createdAt'].split(' ')[0];
        listChild.appendChild(listChildP);

        dom.appendChild(list);
    }
    // var list = document.createElement("div");
    // list.className = 'box';
    // dom.appendChild(list);
}

const Statement = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const serviceCode = sessionStorage.getItem("serviceCode");
    const service = sessionStorage.getItem("service");
    const GameCode = (service === 'buy' || service === 'sell' || service === 'sending')?'bcl':serviceCode;
    
    let num = 0;

    if(!user){
        Alert('error','Login is required.');
        return;
    }

    const addClick = () => {
        num = num + 1;
        History(num);
    }
    
    const History = (number) => {
        axios.get(`https://maxform.link/api/exchange/fbcjfrl/swapHistory.php?Email=${user['key']}&GameCode=${GameCode}&offset=${number}`)
        .then((res) => {
            if(res.data !== 'failed'){
                if(!res.data || res.data.length === 0){
                    Alert('error','No transaction information.');
                    return;
                }
                addList(res.data);
            }else{
                Alert('error','An error occurred on the server.');
                return;
            }
        })
    }
    History(num);
    return (
        <div className='statement'>
            <div className='swapBoard' id='statementList'>
                <div className='box'></div>
            </div>
            <div className='middle'></div>
            <div>
                <button onClick={()=>addClick()}><img src={`${process.env.PUBLIC_URL}img/icon/down_1.png`} alt=''/></button>
            </div>
        </div>
    );
}

export default observer(Statement);