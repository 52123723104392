import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import lang from '../../lang/lang';
import Alert from '../../utils/alert';
import payDetail from '../../assets/pay_detail.png';
import '../../css/pages/nftrental/payforrental.scss';

const BASE_URL = 'https://maxform.link/api/exchange/nft'

const PayForRental = (props) =>{
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [amount, setAmount] = useState(0);
  const [confirmed, setConfirmed] = useState(false);

  const navigate = useNavigate();
  const {state} = useLocation();

  const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
  const walletConnect = window.localStorage.getItem("wagmi.connected");
  const user = JSON.parse(sessionStorage.getItem("user"));

  const handleCheck = (event) => {
    var confirm = false;
    if (event.target.checked) {
      confirm = true;
    } else {
    }
    setConfirmed(confirm);
  };

  const handleClickButton = e => {
    if (confirmed) {
      const { name } = e.target;
      if (name === 'registration') {
        navigate('/nft/registration')
      } else if (name === 'mynft') {
        navigate('/nft/mynft')
      }
    } else {

    }
  };

  const rentRequest = async () => {
      try {
          if (walletAccount.state.data.account) {
              let url = ''
              if (user && walletAccount) {
                  url = BASE_URL + `/rent.php?email=${user.key}&account=${walletAccount.state.data.account}&token_id=${state.token_id}`
              }
              const response = await axios.post(url);
              if (response.data.status === "error") { // 'error'로 변경되었습니다.
                  Alert('error', response.data.message)
              } else if (response.data.status === "success") {
                  alert(response.data.message);
                  navigate('/nft/mynft')
              } else {
                  // 예상치 못한 상태나 메시지를 처리
                  Alert('warning', 'Unexpected response from server');
              }
          } else {
              Alert('error', lang.alert.not_connected_wallet)
          }
      } catch (e) {
          setError(e);
          Alert('error', 'An error occurred: ' + e.message); // 에러 메시지를 띄웁니다.
      }

      setLoading(false);
  }

  useEffect(() => {
    const fetchNft = async () => {
      try {
        let url = ''
        if (user && walletAccount) {
          url = BASE_URL + `/card-info.php?email=${user.key}&account=${walletAccount.state.data.account}&token_id=${state.token_id}`
        }
        const response = await axios.get(url);
        setAmount(response.data[0].pay_amount);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    setTokenId(state.token_id);

    if (state.token_id) {
      fetchNft();
    }
  }, []);

  if (loading) return <div>로딩중..</div>;
  if (error) return <div>에러가 발생했습니다</div>;

  return (
    <div className='payforrental'>
      <div className='container'>
        <button
          className='button type1' onClick={handleClickButton} name={'rental'}>{'NFT\nRental'}</button>
        <button
          className='button' onClick={handleClickButton} name={'registration'}>{'NFT\nRegistration'}</button>
        <button
          className='button' onClick={handleClickButton} name={'mynft'}>{'My\nNFT'}</button>
      </div>
      {(user && walletAccount) ?
      <div className='content'>
        <div className='title'>NFT Rental > NFT Rental > Pay for Rental</div>
        <div className='payment'>
          <div className='cost'>{amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</div>
          <button className='btn-pay'>G.Point</button>
        </div>
        <div className='description'>
          <div className='detail'>
            <img className='img' src={payDetail}/>
            <div className='text'>{amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} G.Point Rental Cost</div>
          </div>
        </div>
        <div className='policy'>
          <div className='notice-box'>
            <div className='notice-text-box'>
              <div className='notice'>Notice</div>
              <div className='text'>I agree that you will not receive any benefits if I sell during the rental period.</div>
            </div>
          </div>
          <div className='description'>I agree to the terms and conditions and privacy policy.</div>
          <div className='ch-box'>
            <input type="checkbox" onChange={handleCheck}/>
            <div className="text">Confirmed</div>
          </div>
        </div>
      </div>
      : <div className='title'>Please Login in and Access Wallet</div>
      }
      <div className='footer'>
          <button className='btn-start' onClick={rentRequest}>Start</button>
      </div>
    </div>
  );
}

export default PayForRental;