import React ,{useState} from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import Layer from '../../config/layer';
import Alert, {AlertCloseEvent} from '../../utils/alert';
import lang from '../../lang/lang';
import '../../css/pages/layer/signUp.scss';

const SignUp = () =>{
    const [input, setInput] = useState({
        email:'',
        emailCode:'',
        nick:'',
        password:'',
        rePassword:''
    });
    const {email,emailCode,nick,password,rePassword} = input;
    const changeInput = (e) => {
        const val = e.target.value;
        setInput({
            ...input,
            [e.target.name]:val
        });
    }

    const privateBtn = (num) => {
        const btn = document.querySelector('#pwdInput'+num);
        const img = document.querySelector('#privateImg'+num);
        btn.type = btn.type === 'password'?'text':'password';
        img.src = btn.type === 'password'?'img/icon/privateOffLogo_1.png':'img/icon/privateOnLogo_1.png';
    }
    
    const emailCheck = () => {
        const inputId = document.querySelector("#emailInput");
        let regex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
        return regex.test(inputId.value) ? true : false ; 
    }
    
    const nextBtn = () => {
        Alert('success',lang.alert.wait);
        nickCheckBtn();
    }
    const sendBtn = () => {
        if(emailCheck()){
            axios.get(`https://bitcoinlegend.io/api/auth/checkEmailV3?email=${email}`)
            .then((res) => {
                if(res.data === 'no_email' && res.data.provider !== 'google' && res.data.provider !== 'apple'){
                    emailCertification();
                }else{
                    Alert('error',lang.alert.different_email);
                }
            })
            .catch((err) => {
                Alert('warning',lang.alert.try);
            })
        }else{
            Alert('error',lang.alert.not_email_format);
        }
    }
    const checkBtn = () => {
        axios.get(`https://bitcoinlegend.io/api/auth/verifyEmailAuth?email=${email}&code=${emailCode}`)
        .then((res) => {
            if(res.data === 'success'){
                document.querySelectorAll('.signUp .before').forEach(element=>element.style.display = 'none');
                document.querySelectorAll('.signUp .after').forEach(element=>element.style.display = 'block');
            }else{
                Alert('error',lang.alert.not_code);
            }
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    }

    const nickCheckBtn = () => {
        axios.get(`https://bitcoinlegend.io/api/auth/checkNickname?nickname=${nick}`)
        .then((res) => {
            AlertCloseEvent();
            if(res.data === 'success'){
                register();
            }else{
                Alert('error',lang.alert.duplication_nick);
            }
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    }
    
    const register = () => {
        if(rePassword === password){
            if(!password)
            {
                return Alert('error',lang.alert.no_password);
            }
            axios.post(`https://bitcoinlegend.io/api/auth/emailRegisterV2`,{
                email : email,
                password : password,
                name : nick,
                realName : '',
                emailVerificationCode : emailCode
            })
            .then((res) => {
                AlertCloseEvent();
                if(res.data.key){
                    window.sessionStorage.setItem("user", JSON.stringify(res.data));
                    loadMyAccount(res.data.key);
                }else{
                    Alert('error',lang.alert.password_error);
                }
            })
            .catch((err) => {
                Alert('warning',lang.alert.try);
            })
        }else{
            Alert('error',lang.alert.password_different);
        }
    }

    const loadMyAccount = (id) => {
        axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${id}`)
        .then((res) => {
            if(res.data){
                window.sessionStorage.setItem("userData", JSON.stringify(res.data));
            }
            window.location.replace('/');
        })
        .catch((err) => {
            window.sessionStorage.removeItem('user');
            window.location.replace('/');
        })
    }
    
    const enterKey = (type='next') => {
        window.event.keyCode === 13 && AlertCloseEvent();
        if(type === 'send')
        {
            window.event.keyCode === 13 && sendBtn();
        }
        else if(type === 'check')
        {
            window.event.keyCode === 13 && checkBtn();
        }
        else
        {
            window.event.keyCode === 13 && nextBtn();
        }
    }


    const emailCertification = (id) => {
        axios.post(`https://bitcoinlegend.io/api/auth/requestEmailAuth`,{
            email : email
        })
        .then((res) => {
            if(res.data === 'success'){
                Alert('success',lang.alert.email_code_check);
            }else{
                Alert('error',lang.alert.email_send_failed);
            }
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    }
    return (
    <div className='signUp'>
        <div className='content_box'>
            <div className='title'>
                <p>Sign Up</p>
            </div>
            <div className='input_box before'>
                <div>
                    <p>E-mail</p>
                    <input id='emailInput' name='email' onChange={changeInput} onKeyUp={()=>enterKey('send')}/>
                    <button onClick={()=>sendBtn()} className='emailBtn'>Send</button>
                </div>
                <div>
                    <p>E-mail Code</p>
                    <input name='emailCode' onChange={changeInput} onKeyUp={()=>enterKey('check')}/>
                    <button onClick={()=>checkBtn()} className='emailBtn'>Check</button>
                </div>
            </div>
            <div className='input_box after'>
                <div>
                    <p>User Nickname</p>
                    <input name='nick' onChange={changeInput}  onKeyUp={()=>enterKey()}/>
                </div>
                <div>
                    <p>Password</p>
                    <input id='pwdInput1' type='password' name='password' onChange={changeInput}  onKeyUp={()=>enterKey()}/>
                    <button onClick={() => privateBtn('1')}><img id='privateImg1' src={`${process.env.PUBLIC_URL}/img/icon/privateOffLogo_1.png`}  alt=''/></button>
                </div>
                <div>
                    <p>Re-Password</p>
                    <input id='pwdInput2' type='password' name='rePassword' onChange={changeInput}  onKeyUp={()=>enterKey()}/>
                    <button onClick={() => privateBtn('2')}><img id='privateImg2' src={`${process.env.PUBLIC_URL}/img/icon/privateOffLogo_1.png`}  alt=''/></button>
                </div>
            </div>
            <div className='signBtn after'>
                <button onClick={()=>nextBtn()}><img src={`${process.env.PUBLIC_URL}/img/button/btn_background_1.png`} alt=''/><p>NEXT</p></button>
            </div>
        </div>
        <div className='back_box' onClick={()=>Layer('login')}><img src={`${process.env.PUBLIC_URL}/img/page/layer/background_1.png`} alt=''/></div>
    </div>
    );
}


export default observer(SignUp);