import React from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../../lang/lang';
import '../../css/pages/bclusage/gamePick.scss';

const GamePick = () =>{
    const sessionGame = JSON.parse(window.sessionStorage.getItem('game'));
    const dom = document.querySelector('#usageContent');
    const src = dom.dataset.code !== 'undefined' ? `${process.env.PUBLIC_URL}/img/game/${dom.dataset.code}_info${getLangImg}.png` : `${process.env.PUBLIC_URL}/img/page/bclusage/background_1${getLangImg}.png`;
    return (
        <div className='gamePick'>
            <div className='text'>
                <p>{lang.bclusage.ment1}</p>
            </div>
            <div className='img'>
                <img src={src} onError={langImgError} alt='' />
            </div>
            {
                sessionGame.map((i,j)=>{
                    if(i.code === dom.dataset.code){
                        return (
                            <div className='button'  key={j}>
                                <div>
                                    <button onClick={() => {window.location.replace(i.playURL)}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/button/store_google_1.png`} alt='' />
                                    </button>
                                </div>
                                <div>
                                    <button onClick={() => {window.location.replace(i.appleURL)}}>
                                        <img src={`${process.env.PUBLIC_URL}/img/button/store_apple_1.png`} alt='' />
                                    </button>
                                </div>
                            </div>
                        );
                    }
                })
            }   
        </div>
    );
}

export default observer(GamePick);