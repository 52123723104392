import React from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom/client';
import service from './service';
import {getLangImg,langImgError} from '../../lang/lang';

const Basic = () => {
    const sessionGame = JSON.parse(window.sessionStorage.getItem('game'));
    let session = sessionGame[0];

    const btn = (type) => {
        const sessionCode = window.sessionStorage.getItem('serviceCode');
        window.sessionStorage.setItem("service", type);
        sessionGame.forEach((i,j) => {
            session = i.code === sessionCode ? i : session ;
        });
        const div = document.createElement("div");
        const root = ReactDOM.createRoot(div);
        var element = React.createElement('div', {  }, service(type,session));
        root.render(element);
    
        const dom = document.querySelector('#serviceType');
        dom.replaceChildren();
        dom.appendChild(div);
    }
    return (
        <div className='serviceEvent'>
            <div className='button_box type2'>
                <button onClick={() => btn('buy')}><img src={`${process.env.PUBLIC_URL}/img/page/service/btn_1${getLangImg}.png`} onError={langImgError} alt=''/></button>
                <button onClick={() => btn('sell')}><img src={`${process.env.PUBLIC_URL}/img/page/service/btn_2${getLangImg}.png`} onError={langImgError} alt=''/></button>
                <button onClick={() => btn('balance')}><img src={`${process.env.PUBLIC_URL}/img/page/service/btn_3${getLangImg}.png`} onError={langImgError} alt=''/></button>
                <button onClick={() => btn('swap')}><img src={`${process.env.PUBLIC_URL}/img/page/service/btn_4${getLangImg}.png`} onError={langImgError} alt=''/></button>
            </div>
            <div className='button_box type2'>
                <button onClick={() => btn('sending')} className='type2'><img src={`${process.env.PUBLIC_URL}/img/page/service/btn_5${getLangImg}.png`} onError={langImgError} alt=''/></button>
            </div>
            <div className='box'></div>
        </div>
    );
}

export default observer(Basic);