const Text = {
    coming_soon : "Coming Soon",
    preparing : "Preparing",
    try : 'Please try again later',
    wait : 'please wait for a moment',
    password_error : 'Password is incorrect.',
    password_different : 'The passwords are different.',
    password_enter : 'Please enter your password',
    platform_id_exists : 'This email has already been registered. Please change the login method',
    different_login : "Try login a different way.",
    different_email : 'Email ID exists. Please try with that login',
    different_google : "Google ID exists. Please try with that login",
    different_apple : "Apple ID exists. Please try with that login",
    app_signup : 'You can sign up as a member through the app.',
    id_check_error : 'ID Chack error',
    email_code_check : 'Please check your email and enter the code',
    email_format : 'ID must be in email format',
    email_format2 : 'E-mail must be in email format',
    email_send_failed : 'Email sending failed',
    email_success : 'Sending Complete!\nThank you for your interest!',
    email_try : 'Please fill out all the data.\nAnd try again!',
    no_login : 'Login is required.',
    no_member : 'There is no member information.',
    no_password : 'Please enter a password.',
    no_rate : 'There is a problem with the server.',
    no_check : 'Please check',
    no_gpoint : 'G.Points must be greater than zero.',
    no_balance : 'Your balance is insufficient.',
    no_percentage : 'please set the percentage',
    no_game_data : 'No Game Data. Please apply after playing the game.',
    not_code : 'Your code is wrong.',
    not_email_format : 'It is not an email format.',
    duplication_nick : 'Your nickname is duplicated.',
    char_limit : 'There is a character limit.',
    connect_wallet : 'A wallet connection is required.',
    change_binance : 'Or Change the network to Binance Chain',
    min_val : 'The minimum exchange amount is',
    max_val : 'A number higher than the balance.',
    gas_success : 'Gas has been completed. Charging time is required due to the transaction time.',
    payment_success : 'Payment has been completed. Charging time is required due to the transaction time.',
    payment_success2 : 'Payment has been completed.',
    payment_failed : 'There is a problem with your transaction. please try again',
    payment_bcl : 'Payment can be made through the BCL APP.',
    payment_after : 'The transaction has already been processed. Please try again after refreshing',
    payment_one_day : 'Sending is only possible once a day.',
    only_one_requested : 'Only one can be requested.',
    not_connected_wallet : 'Please connect wallet.',
}

export default Text;