import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import lang from '../../lang/lang';
import axios from 'axios';
import '../../css/pages/nftrental/rental.scss';
import Alert from '../../utils/alert';
import rentalRequest from '../../assets/rental_request.png';
import defaultImg from '../../assets/temp.png';

const BASE_URL = 'https://maxform.link/api/exchange/nft'

const Rental = (props) =>{
    const [nftList, setNftList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [checked, setChecked] = useState([]);
    const [ref, inView] = useInView();

    const offset = (page - 1) * limit;
    const navigate = useNavigate();
    const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
    const walletConnect = window.localStorage.getItem("wagmi.connected");
    const user = JSON.parse(sessionStorage.getItem("user"));

    const rentRequest = () => {
      if (checked.length == 1) {
        navigate('/nft/rental/pay', {
          state: {
            token_id: checked[0],
          }
        })
      } else {
        Alert('error',lang.alert.only_one_requested)
      }
    };

    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    };

    const handleClickButton = e => {
      const { name } = e.target;
      if (name === 'registration') {
        navigate('/nft/registration')
      } else if (name === 'mynft') {
        navigate('/nft/mynft')
      }
    };

    useEffect(() => {
      const fetchList = async () => {
        try {
          setError(null);
          setNftList(null);
          setLoading(true);
          let url = ''
          if (user && walletAccount) {
            url = BASE_URL + `/rent-list.php?email=${user.key}&account=${walletAccount['state']['data']['account']}`
              // alert(url);
          }
          const response = await axios.get(url);
          setNftList(response.data);
        } catch (e) {
          setError(e);
        }
        setLoading(false);
      };

      if (nftList.length == 0 && user) {
        fetchList();
        console.log(nftList)
      }
    }, []);

    useEffect(()=>{
      if(nftList.length !=0 && inView) {
        setPage(page + 1);
      }
    },[inView]);

    if (loading) return <div>로딩중..</div>;
    if (error) return <div>에러가 발생했습니다</div>;
    if (!nftList) return null;

    return (
      <div className='rental'>
        <div className='container'>
          <button
            className='button type1'>{'NFT\nRental'}</button>
          <button
            className='button' onClick={handleClickButton} name={'registration'}>{'NFT\nRegistration'}</button>
          <button
            className='button' onClick={handleClickButton} name={'mynft'}>{'My\nNFT'}</button>
        </div>
        <div className='content'>
            <div className='title'>NFT Rental > NFT Rental</div>
            <img className='btn-img' src={rentalRequest} onClick={rentRequest}/>
            <div className="list-container">
              {/*{nftList.length != 0 ? nftList.slice(0, limit + offset).map((item, index) => (*/}
              {/*  <div className='nft-box' key={index}>*/}
              {/*    <input value={item.token_id} type="checkbox" onChange={handleCheck}/>*/}
              {/*     <img className='nft-img' src={'../../img/nft/' + (parseInt(item.token_id, 10) +1).toString() +'.png'}/>*/}
              {/*    /!*<img className='nft-img' src={*!/*/}
              {/*    /!*  (item.token_id) ?*!/*/}
              {/*    /!*  'http://43.200.111.124:3000/img/nft/' + (parseInt(item.token_id, 10) +1).toString() +'.png'*!/*/}
              {/*    /!*  : {defaultImg}*!/*/}
              {/*    /!*}/>*!/*/}
              {/*  </div>*/}
              {/*))*/}
              {/*  : <div className='title'>Please Login in and Access Wallet</div>*/}
              {/*}*/}
                {
                    nftList.length !== 0
                        ? nftList.slice(0, limit + offset).map((item, index) => (
                            <div className='nft-box' key={index}>
                                <input value={item.token_id} type="checkbox" onChange={handleCheck}/>
                                {/* 로컬 이미지 사용 */}
                                <img className='nft-img' src={`../../img/nft/${parseInt(item.token_id, 10) + 1}.png`}/>
                            </div>
                        ))
                        // : <div className='title'>Please Login in and Access Wallet</div>
                        : <div className='title'>Empty</div>
                }

            </div>
            <div ref={ref} />
        </div>
      </div>
    );
}

export default Rental;