import axios from "axios";
import lang from '../lang/lang';

const Apple = () => {
    const params = new URLSearchParams(window.location.search);
    let api = JSON.parse(params.get("api"));
    let json = params.get("api");
    if(!api){
        return;
    }

    const loadMyAccount = (id) => {
        axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${id}`)
        .then((res) => {
            if(res.data){
                window.sessionStorage.setItem("userData", JSON.stringify(res.data));
            }else{
                window.sessionStorage.removeItem('user');
                alert(lang.alert.try);
            }
            window.location.replace('/');
        })
        .catch((err) => {
            window.sessionStorage.removeItem('user');
            alert(lang.alert.try);
            window.location.replace('/');
        })
    }
    axios.get(`https://maxform.link/api/exchange/appleLogin.php?apple_json=${json}`)
    .then((res) => {
        if(res.data === 'failed')
        {
            alert(lang.alert.no_member);
            window.location.replace('/');
        }
        else if(res.data === 'nobcl')
        {
            alert(lang.alert.app_signup);
            window.location.replace('/');
        }
        else if(res.data === 'check_provider')
        {
            alert(lang.alert.different_login);
            window.location.replace('/');
        }
        else
        {
            window.sessionStorage.setItem("user", JSON.stringify(res.data));
            loadMyAccount(res.data.key);
        }
    })
    .catch((err) => {
        alert(lang.alert.try);
        window.location.replace('/');
    })
    return;
}
export default Apple;

// {
//     "state":"test",
//     "code":"c50d317be38c742c0beb19d8743de014c.0.nruy.1NtQvAmp9uhyrsMj1mp7kg",
//     "id_token":"eyJraWQiOiI4NkQ4OEtmIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLndoaXRlcGFlay5zZXJ2aWNlcyIsImV4cCI6MTU5ODgwMDEyOCwiaWF0IjoxNTk4Nzk5NTI4LCJzdWIiOiIwMDAxNDguZjA2ZDgyMmNlMGIyNDgzYWFhOTdkMjczYjA5NzgzMjUuMTcxNyIsIm5vbmNlIjoiMjBCMjBELTBTOC0xSzgiLCJjX2hhc2giOiJ1aFFiV0gzQUFWdEc1OUw4eEpTMldRIiwiZW1haWwiOiJpNzlmaWl0OWIzQHByaXZhdGVyZWxheS5hcHBsZWlkLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImlzX3ByaXZhdGVfZW1haWwiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNTk4Nzk5NTI4LCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.GQBCUHza0yttOfpQ-J5OvyZoGe5Zny8pI06sKVDIJaQY3bdiphllg1_pHMtPUp7FLv3ccthcmqmZn7NWVoIPkc9-_8squ_fp9F68XM-UsERKVzBvVR92TwQuKOPFr4lRn-2FlBzN4NegicMS-IV8Ad3AKTIRMIhvAXG4UgNxgPAuCpHwCwEAJijljfUfnRYO-_ywgTcF26szluBz9w0Y1nn_IIVCUzAwYiEMdLo53NoyJmWYFWu8pxmXRpunbMHl5nvFpf9nK-OGtMJrmZ4DlpTc2Gv64Zs2bwHDEvOyQ1WiRUB6_FWRH5FV10JSsccMlm6iOByOLYd03RRH2uYtFw",
//     "user":"{
//       \"email\":\"i79fiit9b3@privaterelay.appleid.com\",
//       \"name\":{
//         \"firstName\":\"SEUNGJOO\",
//         \"lastName\":\"PAEK\"
//       }
//     }"
//   }