const Text = {
    coming_soon : "即将推出",
    preparing : "准备中",
    try : '请稍后再试',
    wait : '请稍等片刻',
    password_error : '密码不正确。',
    password_different : '密码不一致。',
    password_enter : '请输入密码',
    platform_id_exists : '该电子邮件已注册。请更改登录方式',
    different_login : "尝试使用其他方式登录。",
    different_email : '电子邮件 ID 已存在。请尝试该登录。',
    different_google : "Google ID 已存在。请尝试该登录。",
    different_apple : "Apple ID 已存在。请尝试该登录。",
    app_signup : '您可以通过应用程序注册成为会员。',
    id_check_error : 'ID 检查错误',
    email_code_check : '请检查您的电子邮件并输入代码',
    email_format : 'ID 必须是电子邮件格式',
    email_format2 : '电子邮件必须是电子邮件格式',
    email_send_failed : '电子邮件发送失败',
    email_success : '发送完成！\n谢谢您的关注！',
    email_try : '请填写所有数据。\n然后重试！',
    no_login : '需要登录。',
    no_member : '没有会员信息。',
    no_password : '请输入密码。',
    no_rate : '服务器出现问题。',
    no_check : '请检查',
    no_gpoint : 'G.点必须大于零。',
    no_balance : '您的余额不足。',
    no_percentage : '请设置百分比',
    no_game_data : '没有游戏数据。请在游戏后申请。',
    not_code : '您的代码有误。',
    not_email_format : '这不是电子邮件格式。',
    duplication_nick : '您的昵称已重复。',
    char_limit : '有字符限制。',
    connect_wallet : '需要钱包连接。',
    change_binance : '或将网络更改为币安链',
    min_val : '最低兑换金额为',
    max_val : '高于余额的数字。',
    gas_success : 'Gas 已完成。由于交易时间，需要充电时间。',
    payment_success : '支付已完成。由于交易时间，需要充电时间。',
    payment_success2 : '支付已完成。',
    payment_failed : '您的交易有问题。请重试',
    payment_bcl : '可以通过 BCL 应用程序进行支付。',
    payment_after : '交易已处理。请在刷新后再试一次',
    payment_one_day : "每天只能发送一次。",
}

export default Text;