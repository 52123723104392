import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../../css/pages/nftrental/mynft.scss';
import Tab from '../../utils/tab';
import defaultImg from '../../assets/temp.png';

const BASE_URL = 'https://maxform.link/api/exchange/nft'

const MyNft = (props) =>{
  const [nftList, setNftList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
  const walletConnect = window.localStorage.getItem("wagmi.connected");
  const user = JSON.parse(sessionStorage.getItem("user"));

  const MySwal = withReactContent(Swal)

  const handleClickButton = e => {
    const { name } = e.target;
    if (name === 'registration') {
      navigate('/nft/registration')
    } else if (name === 'rental') {
      navigate('/nft/rental')
    }
  };

  const handleActive = async (tokenId, e) => {
    if (e.target.textContent == 'Active') {
      try {
        const result = await MySwal.fire({
          title: 'Do you want to disable this NFT?',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = ''
          if (user && walletAccount) {
              const url = BASE_URL + `/regist-cancel.php`;
              const data = {
                email: user.key,
                account: walletAccount.state.data.account,
                token_id: tokenId,
              };
    
              const headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
              };

              setLoading(true)
    
              try {
                const response = await axios.post(url, data, { headers });
                console.log('Response:', response.data); // Logging the response data
                if (response.data.code == 200) {
                } else {
                  Swal.fire('Please Try Again.', '', 'failed')
                  return
                }
              } catch (error) {
                console.error('Error:', error);
                Swal.fire('Please Try Again.', '', 'failed')
              }
          }
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    }
  };

  const checkStatus = (status) => {
    var res= {state: '', text: ''}
    if (status == "0") {
      res.state = 'inactive';
      res.text = 'Inactive';
    } else if (status == "1") {
      res.state = 'active';
      res.text = 'Active';
    } else if (status == "2") {
      res.state = 'renting';
      res.text = 'Renting';
    } else if (status == "3") {
      res.state = 'myself';
      res.text = 'Using myself';
    }
    return res
  }
    
  useEffect(() => {
    const fetchList = async () => {
      try {
        const response = await axios.get(
            // BASE_URL + '/my-nft-list.php?email=test123@test.com&account=0xtestaccount'
            BASE_URL + `/my-nft-list.php?email=${user.key}&account=${walletAccount['state']['data']['account']}`
        );
        setNftList(response.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    fetchList();
  }, []);

  if (loading) return <div>로딩중..</div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!nftList) return null;

  return (
    <div className='mynft'>
      <div className='container'>
        <button
          className='button' onClick={handleClickButton} name={'rental'}>{'NFT\nRental'}</button>
        <button
          className='button' onClick={handleClickButton} name={'registration'}>{'NFT\nRegistration'}</button>
        <button
          className='button type1' onClick={handleClickButton} name={'mynft'}>{'My\nNFT'}</button>
      </div>
      <div className='content'>
          <div className='title'>NFT Rental > My NFT</div>
          <Tab/>
          {(user && walletAccount) ?
          <div className="list-container">
          {nftList.map((item, index) => (
            <div className='nft-box' key={index}>
              {/* <img className='nft-img' src={'../../img/nft/' + (parseInt(item.token_id, 10) +1).toString() +'.png'}/> */}
              <img className='nft-img' src={
                (item.token_id) ?
                '../../img/nft/' + (parseInt(item.token_id, 10) +1).toString() +'.png'
                : {defaultImg}
                }
                />
              <div 
                className={'nft-' + checkStatus(item.state).state}
                onClick={(e) => {handleActive(parseInt(item.token_id), e)}}
              >
                {checkStatus(item.state).text}
              </div>
            </div>
          ))}
        </div>
        : <div className='title'>Please Login in and Access Wallet</div>
        }
      </div>
    </div>
  );
}

export default MyNft;