const Text = {
    title : "Thông tin riêng tư",
    welcome : "Chào mừng bạn!",
    content1 : "Phải kết nối Metamask.",
    content2 : "Chuyển mạng của bạn thành BNB.",
    content3 : "Thanh toán trên web được thực hiện bằng các chương trình mở rộng Metamask.",
    content4 : "Thanh toán trên điện thoại được thực hiện trong ứng dụng đào tạo.",
    confirm : 'Xác nhận',
    next : 'Tiếp tục',
}

export default Text;