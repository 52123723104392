import header from './zh/header';
import layer from './zh/layer';
import alert from './zh/alert';
import main from './zh/main';
import wallet from './zh/wallet';
import contact from './zh/contact';
import bclusage from './zh/bclusage';
import service from './zh/service';
import selectGame from './zh/selectGame';
import nftIntro from './zh/nftintro';

const zh = {
    header : header,
    layer : layer,
    alert : alert,
    main : main,
    wallet : wallet,
    contact : contact,
    bclusage : bclusage,
    service : service,
    selectGame : selectGame,
    nftIntro : nftIntro
}

export default zh;