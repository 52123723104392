import lang from '../lang/lang';

const header_menu_list = [
    {path: '/', code: 'main'},
    {path: '/selectGame', code: 'selectGame'},
    {path: '/service', code: 'service'
        // , children: [
        //     {path: '/sevice/buyGmoney', code: 'sevice_buyGmoney'},
        //     {path: '/sevice/sellGmoney', code: 'sevice_sellGmoney'},
        //     {path: '/sevice/balance', code: 'sevice_balance'},
        //     {path: '/sevice/swap', code: 'sevice_swap'},
        //     {path: '/sevice/sending', code: 'sevice_sending'}
        // ]
    },
    {path: '/nft', code: 'nftRental'
        , children: [
            {path: '/nft', code: 'nftRental_intro'},
            {path: '/nft/ranking', code: 'nftRental_ranking'},
            {path: '/nft/rental', code: 'nftRental_rental'},
            {path: '/nft/registration', code: 'nftRental_registration'},
            {path: '/nft/mynft', code: 'nftRental_myNft'}
        ]  
    },
    {path: '/bclUsage', code: 'bclUsage'},
    {path: '/contact', code: 'contact'}
];
const gathering = (a,b) => {
    let data = [];
    let num = 0;
    a.map( x => {
        return (
            b.map( y => {
                if(x.code === y.code){
                    data[num] = {...x, ...y};
                    data[num].children = x.children !== undefined && gathering(x.children,b);
                    num++;
                }
                return data;
            })
        )
    });
    return data;
}
const header_menu = gathering(header_menu_list,lang.header.menu_list);

const menu = {
    header_menu : header_menu
}

export default menu;