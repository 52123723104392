const Text = {
    layer_list : [
        {code: 'login', title: '登录'},
        {code: 'signIn', title: '登录'},
        {code: 'signUp', title: '注册'},
        {code: 'profile', title: '我的资料'}
    ],
    login_content : "我们公司运营NFT游戏、\n游戏货币兑换和BCL挖掘应用程序。\n我们将这些功能整合在一起。\n请查看并注册。\n如果您更改了它，\n它将会全部更改，\n所以请参考它。",
    signin_title : "注意事项",
    signin_content : "我们公司运营游戏、货币兑换和BCL挖掘应用程序。",
}

export default Text;