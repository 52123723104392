import React,{useState} from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { observer } from 'mobx-react';
import Alert, {AlertCloseEvent} from '../utils/alert';
import Slide from '../utils/slide';
import Basic from './service/basic';

import '../css/pages/service.scss';

const Service = () => {
    const sessionGame = JSON.parse(window.sessionStorage.getItem('game'));
    window.sessionStorage.setItem("serviceCode", sessionGame[0].code);

    const rate = JSON.parse(window.sessionStorage.getItem('rate'));
    if(!rate){
        axios.get(`https://maxform.link/api/exchange/fbcjfrl/getRate.php`)
        .then((res) => {
            window.sessionStorage.setItem("rate", JSON.stringify(res.data));
        })
    }

              
    const slideClick = (code=null) => {
        const dom = document.querySelector('#serviceType');
        if(code !== null)
        {
            window.sessionStorage.setItem("serviceCode", code);
            const div = document.createElement("div");
            const root = ReactDOM.createRoot(div);
            var element = React.createElement('div', null, <Basic />);
            root.render(element);
            dom.replaceChildren();
            dom.appendChild(div);
        }
        else
        {
            dom.childNodes[0] && dom.removeChild(dom.childNodes[0]);
        }
    }
    
    let data = [];
    sessionGame.forEach((i,j) => {
        data[j] = {
            img : i.imgURL,
            code : i.code,
            name : i.name,
            click : slideClick
        }
    });
    for(var j=2;j<8;j++){
        data[j] = {
            img : `${process.env.PUBLIC_URL}img/icon/basic.png`,
            name : 'soon',
            click : slideClick
        }
    }
    return (
        <div className='service'>
            <div className='title type1'>
                <p>Service</p>
            </div>
            <div className='game_box' id='slide'>
                {Slide(data)}
            </div>
            <div className='service_box' id='serviceType'>
                <Basic />
            </div>
        </div>
    );
}
export default observer(Service);