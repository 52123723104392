import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../../lang/lang';
import axios from 'axios';
import Pagination from '../../components/pagination';
import '../../css/pages/nftrental/ranking.scss';
import trophy from '../../assets/trophy.png';
import trophy1 from '../../assets/trophy-1.png';
import trophy2 from '../../assets/trophy-2.png';
import trophy3 from '../../assets/trophy-3.png';

const BASE_URL = 'https://maxform.link/api/exchange/nft'

const Ranking = () => {
    const [myRank, setMyRank] = useState(0);
    const [myProfit, setMyProfit] = useState(0);
    const [rankList, setRankList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const offset = (page - 1) * limit;

    const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
    const walletConnect = window.localStorage.getItem("wagmi.connected");
    const user = JSON.parse(sessionStorage.getItem("user"));

    useEffect(() => {
        const fetchList = async () => {
          try {
            setError(null);
            setRankList(null);
            setLoading(true);
            let url = BASE_URL + '/ranking.php'
            if (user && walletAccount) {
                url = BASE_URL + `/ranking.php?email=${user.key}&account=${walletAccount['state']['data']['account']}`
            }
            const response = await axios.get(url);
            setRankList(response.data.ranking);
            setMyRank(response.data.myrank);
            setMyProfit(response.data.myprofit);
          } catch (e) {
            setError(e);
          }
          setLoading(false);
        };

        if (rankList.length == 0) {
          fetchList();
        }
    }, []);

    if (loading) return <div>로딩중..</div>;
    if (error) return <div>에러가 발생했습니다</div>;
    if (!rankList) return null;

    return (
        <div className='ranking'>
            <div className='back_box1'>
                <div className='text_box'>
                    <div className='title'>NFT Rental > NFT Profit Ranking</div>
                </div>
                <div className='sub-box'>
                    <div className='sub-text-box'>
                        <div className='sub-text'>My Ranking
                            <div className='sub-data'>{
                                myRank != 0
                                ? myRank.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + ' / ' + rankList.length.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                : '- / ' + rankList.length.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                            }
                            </div>
                        </div>
                        <div className='sub-text'>My Profit
                            <div className='sub-data'>{myProfit}</div>
                        </div>
                    </div>
                    <div className='sub-text-box2'>
                        <img className='trophy-icon' src={
                            myRank > 3 ? trophy
                            : myRank == 1 ? trophy1
                            : myRank == 2 ? trophy2
                            : trophy3}
                        />
                        <div className='trophy-text'>{'NFT Rental\nProfit Ranking'}</div>
                    </div>
                </div>
            </div>
            <div className='ranking-board'>
                <div className='ranking-header-box'>
                    <div className='ranking-header type1'>Ranking</div>
                    <div className='ranking-header type2'>E-mail</div>
                    <div className='ranking-header type1'>G.Profit</div>
                </div>
                <div className='ranking-cell-box'>
                {rankList.length != 0 ? rankList.slice(offset, limit + offset).map((item, index) => (
                    <div className='ranking-cell'>
                        <div>{index + offset + 1}</div>
                        <div>{item.email}</div>
                        <div>{item.profit.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </div>
                    ))
                    : <div className='ranking-cell'></div>
                }
                </div>
                <div className='paging-box'>
                    <Pagination
                        total={rankList.length}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
}
export default observer(Ranking);