const Text = {
  benefit : "Benefit",
  title_head : "I know NFT is good,\nbut what are the benefits?",
  description_head : 'There are various benefits such as NFT rental and referral.\nTake advantage of the revenue you want.',
  title_2 : "Let's rent the NFT\nYou get benefits and recommendations!",
  class_benefit: 'Class\nbenefits',
  description_2_1 : 'NFTs offer discrimination based on class-specific rarity\nBenefits for both renters renting NFTs and gamers renting',
  description_2_2 : 'Categorized into four stages, mining speed, G.Point ratio, NFT random delivery, etc',
  description_2_3 : `You can play games or rent them! Now let's choose and benefit!`,
  title_3: 'NFT Rental Service',
  subtitle_3_1: 'NFT Grade',
  subtitle_3_2: 'Benefit',
  title_4: 'Rental rate of profit',
  subtitle_4_1: '1. NFT ownership and application\ncriteria for rental',
  subtitle_4_2: '2. General Gamers / NFT Gamers Earnings Comparison',
  subtitle_4_3: '3. NFT Rental Profit',
  title_4_2: 'NFT Rental Cost System',
  subtitle_4_4: '4. 20,000G.P (NFT Rental Cost / 30D Criteria',
  description_4_4_1: 'If a gamer pays 10,000 G.P. for Rent, You can get\n132,000G per month based on 8hours a day.',
  description_4_4_2: '12,000 G.P(Rental Cost)',
  description_4_4_3: 'Get 12,000 G.P. from\nGamers and Rent\n30 days',
  description_4_4_4: '8,000 G.P(operating expenses)',
  description_4_4_5: 'To reduce distribution,\nthe foundation uses 4,000G.P for incineration.',
  explain_4: '*The benefit settlement will be calculated 30 days from the date of application\nand will be paid on the 25th of next month.',
  title_foot : 'NFT',
  description_foot : `My own virtual world, let's start easy and fun!`,
  btn_foot : 'More Detail',
}

export default Text;