import React from 'react';
import { observer } from 'mobx-react';
import lang from '../../lang/lang';
import Alert from '../../utils/alert';

const Balance = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const serviceCode = sessionStorage.getItem("serviceCode");
    
    if(!user){
        Alert('error',lang.alert.no_login);
        return;
    }
    let userDatakey = 0;
    for(var key in userData['game']){
        userDatakey = userData['game'][key]['code'] === serviceCode ? key:userDatakey;
    }
    const bcl = (userData['bcl']['amount'])?Number(userData['bcl']['amount']):0;
    const gpoint = (userData['gpoint']['amount'])?Number(userData['gpoint']['amount']):0;
    const diamond = (userData['game'][userDatakey]['UDIAMONDS'])?Number(userData['game'][userDatakey]['UDIAMONDS']):0;
    const gold = (userData['game'][userDatakey]['UCOINS'])?Number(userData['game'][userDatakey]['UCOINS']):0;
    return (
        <div className='balance'>
            <div className='swapBoard'>
                <div className='title'><p>Complete!</p></div>
                <div className='subTitle'><p>E-mail</p></div>
                <div className='box2 boundary2'>
                    <p>{user['key']}</p>
                </div>
                <div className='subTitle'><p>BCL</p></div>
                <div className='box2 boundary2'>
                    <p>{bcl.toLocaleString()}</p>
                </div>
                <div className='subTitle'><p>G.point</p></div>
                <div className='box2 boundary2'>
                    <p>{gpoint.toLocaleString()}</p>
                </div>
                <div className='subTitle'><p>Diamond</p></div>
                <div className='box2 boundary2'>
                    <p>{diamond.toLocaleString()}</p>
                </div>
                <div className='subTitle'><p>Gold</p></div>
                <div className='box2 boundary2'>
                    <p>{gold.toLocaleString()}</p>
                </div>
                <div className='box'></div>
            </div>
            <div className='btnType2'>
                {/* <button>Go to Exchange</button> */}
            </div>
        </div>
    );
}

export default observer(Balance);