import React from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import Menu from './menu';
import Layer from '../../config/layer';
import lang from '../../lang/lang';
import '../../css/pages/header/header.scss';

const Header = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    if(user){
        let valGpoint = 0;
        let valGas = 0;
        axios.get(`https://maxform.link/api/exchange/fbcjfrl/myGpoint.php?email=${user['key']}`)
        .then((res) => {
            valGpoint = res.data;
            axios.get(`https://maxform.link/api/exchange/fbcjfrl/myGas.php?email=${user['key']}`)
            .then((res) => {
                valGas = res.data;
                userData['gpoint']['amount'] = String(valGpoint);
                userData['gas']['amount'] = String(valGas);
                sessionStorage.setItem("userData",JSON.stringify(userData));
            })
        })
    }
    const sessionGame = window.sessionStorage.getItem('game');
    if(!sessionGame){
        axios.get('https://maxform.link/api/exchange/LoadGameList.php')
        .then((res) => {
            window.sessionStorage.setItem("game", JSON.stringify(res.data));
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const btn = () => {
        if(user){
            return <button className='profile' onClick={() => Layer(lang.header.button[2].code,false)}>{lang.header.button[2].title}</button>
        }else{
            return <button className='login' onClick={() => Layer(lang.header.button[0].code,false)}>{lang.header.button[0].title}</button>;
        }
    }
    
    const navigate = useNavigate();
    const menu = () => {
        const dom = document.querySelector("#header_menu");
        dom.classList.contains('action')
        ? dom.classList.remove('action')
        : dom.classList.add('action')
    }

    const getLang = window.localStorage.getItem("GlobalState");
    const language = () => {
        const select = document.getElementById('languageSelect');
        window.localStorage.setItem("GlobalState", select.value);
        window.location.replace('/');
    }

    return (
    <div className='header'>
        <div className='topBar'>
            <div className='menuBtn'>
                <button type="button" className="icon" onClick={() => menu()}>
                    <div className="icon-bar"></div>
                    <div className="icon-bar"></div>
                    <div className="icon-bar"></div>
                </button>
            </div>
            <div className='logo left'>{/*etcBtn 일때 'left' class 삽입 (css적용)*/}
                <img className='logo1' src={`${process.env.PUBLIC_URL}/img/icon/logo.png`} alt="" onClick={() => window.location.replace('/')} />
                <img className='logo2' src={`${process.env.PUBLIC_URL}/img/icon/logo2.png`} alt="" onClick={() => window.location.replace('/')} />
            </div>
            {/* <div className='close'>

            </div> */}
            <div className='etcBtn'>
                {btn()}
                <button onClick={() => navigate("/wallet")}>{lang.header.button[1].title}</button>
                <select defaultValue={getLang} id='languageSelect' onChange={()=>language()}>
                    <option value='en'>English</option>
                    <option value='ko'>한국어</option>
                    <option value='vi'>Việt Nam</option>
                    <option value='zh'>中文</option>
                    <option value='ar'>عرب</option>
                </select>
                {/* <ul defaultValue={getLang} id='languageSelect' onChange={()=>language()}>
                    <div class='langClick'><img src={`${process.env.PUBLIC_URL}/img/icon/${getLang}.png`} /></div>
                    <div class='langNone'>
                        <li data-value='en'><img src={`${process.env.PUBLIC_URL}/img/icon/en.png`} /></li>
                        <li data-value='ko'><img src={`${process.env.PUBLIC_URL}/img/icon/ko.png`} /></li>
                        <li data-value='vi'><img src={`${process.env.PUBLIC_URL}/img/icon/vi.png`} /></li>
                        <li data-value='zh'><img src={`${process.env.PUBLIC_URL}/img/icon/zh.png`} /></li>
                        <li data-value='ar'><img src={`${process.env.PUBLIC_URL}/img/icon/ar.png`} /></li>
                    </div>
                </ul> */}
            </div>
        </div>
        <Menu />
    </div>
    );
}

export default observer(Header);
