const Text = {
    coming_soon : "Sắp ra mắt",
    preparing : "Đang chuẩn bị",
    try : 'Vui lòng thử lại sau',
    wait : 'Vui lòng chờ trong giây lát',
    password_error : 'Mật khẩu không đúng.',
    password_different : 'Mật khẩu không khớp.',
    password_enter : 'Vui lòng nhập mật khẩu của bạn',
    platform_id_exists : 'Email này đã được đăng ký. Vui lòng đăng nhập bằng phương thức khác',
    different_login : "Hãy thử đăng nhập bằng cách khác.",
    different_email : 'Email ID đã tồn tại. Vui lòng thử đăng nhập với email đó',
    different_google : "Google ID đã tồn tại. Vui lòng thử đăng nhập với tài khoản đó",
    different_apple : "Apple ID đã tồn tại. Vui lòng thử đăng nhập với tài khoản đó",
    app_signup : 'Bạn có thể đăng ký làm thành viên thông qua ứng dụng.',
    id_check_error : 'Lỗi kiểm tra ID',
    email_code_check : 'Vui lòng kiểm tra email của bạn và nhập mã',
    email_format : 'ID phải trong định dạng email',
    email_format2 : 'E-mail phải trong định dạng email',
    email_send_failed : 'Gửi email không thành công',
    email_success : 'Gửi hoàn tất! Cảm ơn vì sự quan tâm của bạn!',
    email_try : 'Vui lòng điền đầy đủ thông tin và thử lại!',
    no_login : 'Đăng nhập là bắt buộc.',
    no_member : 'Không có thông tin thành viên.',
    no_password : 'Vui lòng nhập mật khẩu.',
    no_rate : 'Có vấn đề với máy chủ.',
    no_check : 'Vui lòng kiểm tra',
    no_gpoint : 'G.Points phải lớn hơn không.',
    no_balance : 'Số dư của bạn không đủ.',
    no_percentage : 'vui lòng thiết lập tỷ lệ phần trăm',
    no_game_data : 'Không có dữ liệu trò chơi. Vui lòng đăng ký sau khi chơi trò chơi.',
    not_code : 'Mã của bạn sai.',
    not_email_format : 'Không phải định dạng email.',
    duplication_nick : 'Tên đăng nhập của bạn đã bị trùng lặp.',
    char_limit : 'Có giới hạn ký tự.',
    connect_wallet : 'Yêu cầu kết nối ví tiền.',
    change_binance : 'Hoặc Thay đổi mạng thành Binance Chain',
    min_val : 'Số tiền trao đổi tối thiểu là',
    max_val : 'Một số lớn hơn số dư.',
    gas_success : 'Gas đã hoàn tất. Cần thời gian để nạp tiền do thời gian giao dịch.',
    payment_success : 'Thanh toán đã hoàn tất. Cần thời gian để nạp do thời gian giao dịch.',
    payment_success2 : 'Thanh toán đã hoàn tất.',
    payment_failed : 'Đã xảy ra sự cố với giao dịch của bạn. Vui lòng thử lại',
    payment_bcl : 'Thanh toán có thể được thực hiện thông qua ứng dụng BCL.',
    payment_after : 'Giao dịch đã được xử lý. Vui lòng thử lại sau khi làm mới',
    payment_one_day : "Chỉ có thể gửi một lần mỗi ngày.",
}

export default Text;