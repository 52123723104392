import header from './ko/header';
import layer from './ko/layer';
import alert from './ko/alert';
import main from './ko/main';
import wallet from './ko/wallet';
import contact from './ko/contact';
import bclusage from './ko/bclusage';
import service from './ko/service';
import selectGame from './ko/selectGame';
import nftIntro from './ko/nftintro';

const ko = {
    header : header,
    layer : layer,
    alert : alert,
    main : main,
    wallet : wallet,
    contact : contact,
    bclusage : bclusage,
    service : service,
    selectGame : selectGame,
    nftIntro : nftIntro
}

export default ko;