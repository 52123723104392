const Text = {
    layer_list : [
        {code: 'login', title: 'تسجيل الدخول'},
        {code: 'signIn', title: 'تسجيل الدخول'},
        {code: 'signUp', title: 'التسجيل'},
        {code: 'profile', title: 'ملفي الشخصي'}
    ],
    login_content : "شركتنا تشغل ألعاب NFT،\nصرف النقود اللعبة، وتطبيق التعدين BCL.\nنحن ندمج هذا.\nلذا، تحقق من ذلك وسجل.\nوإذا قمت بتغييره،\nفسيتم تغييره بأكمله،\nلذا يرجى الرجوع إليه.",
    signin_title : "إشعار",
    signin_content : "تدير شركتنا ألعابًا، صرف العملات، وتطبيقات تعدين BCL."
}

export default Text;