const Text = {
    menu_list : [
        {code: 'main', title: 'Home'},
        {code: 'selectGame', title: 'Select Game'},
        {code: 'service', title: 'Service'},
        {code: 'nftRental', title: 'NFT Rental'},
        {code: 'nftRental_intro', title: 'NFT Intro'},
        {code: 'nftRental_ranking', title: 'NFT Ranking'},
        {code: 'nftRental_rental', title: 'NFT Rental'},
        {code: 'nftRental_registration', title: 'NFT Registration'},
        {code: 'nftRental_myNft', title: 'My NFT'},
        {code: 'bclUsage', title: 'BCL Usage'},
        {code: 'contact', title: 'Contact'},
        {code: 'sevice_buyGmoney', title: 'Buy G.money'},
        {code: 'sevice_sellGmoney', title: 'Sell G.money'},
        {code: 'sevice_balance', title: 'Balance'},
        {code: 'sevice_swap', title: 'Swap'},
        {code: 'sevice_sending', title: 'Sending'}
    ],
    button : [
        {code: 'login', title: 'Login'},
        {code: 'wallet', title: 'Wallet'},
        {code: 'profile', title: 'Profile'}
    ]
}

export default Text;