const Text = {
    type_path_buy: 'G.Money 구매',
    type_path_sell: 'G.Money 판매',
    type_path_swap: '교환',
    type_path_balance: '내 잔액',
    type_path_statement: '내역',
    type_path_sending: '송금',
    top_btn_buy: '구매',
    top_btn_sell: '판매',
    top_btn_swap: '교환',
    top_btn_balance: '잔액',
    top_btn_statement: '내역',
    top_btn_sending: '송금',
    gas_send: "'송금'을 하려면 가스를 충전해야합니다.",
    bnb: "가스를 충전할 때 BNB 네트워크와 입금을 확인하십시오. 다른 코인으로 입금하는 경우 책임지지 않습니다.\n가스를 충전하면 한 번의 송금이 가능합니다.",
    from: '보내는 사람',
    to: '받는 사람',
    quantity: '수량',
    swap: '교환',
    fees: '총 수수료',
    rate: '환율',
    amount: '환전 금액',
    my_amount: '내 금액',
}

export default Text;