import React,{useState} from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import Alert from '../../utils/alert';
import lang from '../../lang/lang';
const Swap = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const rate = JSON.parse(sessionStorage.getItem("rate"));
    const serviceCode = sessionStorage.getItem("serviceCode");
    let userDatakey = 0;
    if(userData){
        for(var key in userData['game']){
            userDatakey = userData['game'][key]['code'] === serviceCode ? key:userDatakey;
        }
    }

    let rateMinAccount = ''; 
    let rateVal = '';

    function Rate(rate,type){
        for(var key in rate) {
            if(rate[key]['code'] === serviceCode && rate[key]['type'] === type)
            {
                rateMinAccount = rate[key]['minAmount'];
                rateVal = rate[key]['rate'];
            }
        }
        rateMinAccount = (type === 'gpoint')?1000:rateMinAccount;
        console.log(rateMinAccount);
        return [rateMinAccount, rateVal];
    }

    const [input, setInput] = useState({
        Quantity  : '0',
        Fees : '0',
        gold : (userData['game'][userDatakey]['UCOINS'])?userData['game'][userDatakey]['UCOINS']:0,
        diamond : (userData['game'][userDatakey]['UDIAMONDS'])?userData['game'][userDatakey]['UDIAMONDS']:0,
        bcl : (userData['bcl'])?userData['bcl'].amount:0,
        gpoint : (userData['gpoint'])?userData['gpoint'].amount:0,
        swapFromType : 'game',
        type : 'gold',
        ratio : 1/Rate(rate,'gold')[1]*0.7*1000,
        min : Rate(rate,'gold')[0],
        amount : 0
    });
    const {Quantity,Fees,diamond,gold,gpoint,bcl,swapFromType,ratio,min,type,amount} = input;

    if(!user){
        Alert('error',lang.alert.no_login);
        return;
    }
    if(!rate){
        alert(lang.alert.no_rate);
        window.location.replace('/');
        return;
    }
    
    const Coin = () => {
        var typeVal = swapFromType === 'game' ? document.querySelector('#swapFrom').value : document.querySelector('#swapTo').value;
        setInput((prevState)=>{return {...prevState,type:typeVal}});
        const coinName = typeVal === 'gold' ? 'UCOINS' : 'UDIAMONDS' ;
        let coin;
        for(var key in userData['game']){
            coin = userData['game'][key]['code'] === serviceCode ? userData['game'][key][coinName]:coin;
        }
        coin = swapFromType === 'game' ? coin : userData['gpoint']['amount'] ;
        setInput((prevState)=>{return {...prevState,ratio:(swapFromType === 'game')?1/Rate(rate,typeVal)[1]*0.7*1000:Number(Rate(rate,typeVal)[1])/1000}});
        setInput((prevState)=>{return {...prevState,min:Number(Rate(rate,swapFromType)[0])}});
        coin = coin === undefined ?0:coin;
        return coin;
    }
    
    const change = () => {//고도화 해야함
        var swapFromTypeVal = (swapFromType === 'game') ? 'gpoint' : 'game';
        setInput((prevState)=>{return {...prevState,type:'gold'}});
        setInput((prevState)=>{return {...prevState,swapFromType:swapFromTypeVal}});
        setInput((prevState)=>{return {...prevState,Quantity:'0'}});
        setInput((prevState)=>{return {...prevState,Fees:'0'}});
        setInput((prevState)=>{return {...prevState,ratio:(swapFromTypeVal === 'game')?1/Rate(rate,type)[1]*0.7*1000:Rate(rate,swapFromTypeVal)[1]/1000}});
        setInput((prevState)=>{return {...prevState,min:Number(Rate(rate,swapFromTypeVal)[0])}});
        document.querySelector('#swapQuantity').options[0].selected = true;
        const swap = {
            from : document.querySelector('#swapFrom'),
            to : document.querySelector('#swapTo')
        };
        for(var key in swap) {
            swap[key].options.length = 0;
        }
        if(swapFromTypeVal !== 'gpoint')
        {
            var option = document.createElement("option");
            option.text = "Gold";
            option.value = "gold";
            swap['from'].options.add(option);
            option = document.createElement("option");
            option.text = "Diamond";
            option.value = "diamond";
            swap['from'].options.add(option);
            option = document.createElement("option");
            option.text = "G.Point";
            option.value = "gPoint";
            swap['to'].options.add(option);
        }
        else
        {
            option = document.createElement("option");
            option.text = "Gold";
            option.value = "gold";
            swap['to'].options.add(option);
            option = document.createElement("option");
            option.text = "Diamond";
            option.value = "diamond";
            swap['to'].options.add(option);
            option = document.createElement("option");
            option.text = "G.Point";
            option.value = "gPoint";
            swap['from'].options.add(option);
        }
    }

    const select = (a) => {
        var typeVal = swapFromType === 'gpoint' ? document.querySelector('#swapTo').value : document.querySelector('#swapFrom').value;
        setInput((prevState)=>{return {...prevState,type:typeVal}});
        var ratioVal = (swapFromType !== 'gpoint') ?1/Rate(rate,typeVal)[1]*0.7*1000:Rate(rate,typeVal)[1]/1000;
        const coin = Coin();
        const quantity = document.querySelector('#swapQuantity').value;
        const amountVal = Math.floor(coin*Number(quantity)/100);
        const percent = 3;//나중에 api 값 불러오기
        const fees = Math.ceil(amountVal*percent/100); 
        setInput((prevState)=>{return {...prevState,amount:Math.floor((amountVal-fees)*ratioVal)}});
        setInput((prevState)=>{return {...prevState,Quantity:amountVal}});
        setInput((prevState)=>{return {...prevState,Fees:(fees === 0)?0:-fees}});
    }
    
    const submit = () => {
        const coin = Coin();
        const quantity = document.querySelector('#swapQuantity').value;
        const amountVal = Math.floor(coin*quantity/100);
        if(min > amountVal){
            Alert('error',lang.alert.min_val+' '+min.toLocaleString()+'.');
            return;
        }
        const apiUrl = swapFromType === 'game' ? 'https://maxform.link/api/exchange/fbcjfrl/game2point.php' : 'https://maxform.link/api/exchange/fbcjfrl/point2game.php';
        const type = swapFromType === 'game' ? document.querySelector('#swapFrom').value : document.querySelector('#swapTo').value ;
        axios.get(`${apiUrl}?email=${user['key']}&gameName=${serviceCode}&amount=${amountVal}&type=${type}`)
        .then((res) => {
            if(res.data === 'success')
            {
                axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${user['key']}`)
                .then((res) => {
                    if(res.data){
                        window.sessionStorage.removeItem('userData');
                        window.sessionStorage.setItem("userData", JSON.stringify(res.data));
                        alert(lang.alert.payment_success2);
                    }
                    window.location.replace('/service');
                })
                .catch((err) => {
                    Alert('warning',lang.alert.try);
                })
            }else if(res.data === 'no_game_data'){
                Alert('error',lang.alert.no_game_data);
            }else{
                Alert('error',lang.alert.payment_failed);
            }
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    }

    return (
        <div className='swap'>
            <div className='swapFromTo'>
                <div>
                    <div>{lang.alert.from}</div>
                    <div>
                        <select id='swapFrom' onChange={()=>select(false)}>
                            <option value='gold'>Gold</option>
                            <option value='diamond'>Diamond</option>
                        </select>
                    </div>
                </div>
                <div>
                    <button onClick={()=>{change()}}><img src={`${process.env.PUBLIC_URL}img/button/swap_1.png`} alt=''/></button>
                </div>
                <div>
                    <div>{lang.alert.to}</div>
                    <div>
                        <select id='swapTo' onChange={()=>select(false)}>
                            <option value='gPoint'>G.Point</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className='quantity'>
                <div className='title'>{lang.service.quantity} <p>{Quantity.toLocaleString()}</p></div>
                <select id='swapQuantity' onChange={()=>select(true)}>
                    <option value='0'>0%</option>
                    <option value='100'>100%</option>
                    <option value='90'>90%</option>
                    <option value='80'>80%</option>
                    <option value='70'>70%</option>
                    <option value='60'>60%</option>
                    <option value='50'>50%</option>
                    <option value='40'>40%</option>
                    <option value='30'>30%</option>
                    <option value='20'>20%</option>
                    <option value='10'>10%</option>
                </select>
            </div>
            <div className='swapBoard'>
                <div className='title'>{lang.service.swap}</div>
                <div className='line'>
                    <div>{lang.service.fees}</div>
                    <div>{Fees.toLocaleString()}</div>
                </div>
                <div className='line'>
                    <div>{lang.service.rate}</div>
                    <div>≒ {Math.floor(ratio*100000)/1000}%</div>
                </div>
                <div className='line'>
                    <div>{lang.service.amount}</div>
                    <div>≒ {amount.toLocaleString()}</div>
                </div>
            </div>
            <div className='swapBoard margin'>
                <div className='title'>{lang.service.my_amount}</div>
                <div className='line'>
                    <div>BCL</div>
                    <div>{Number(bcl).toLocaleString()}</div>
                </div>
                <div className='line'>
                    <div>G.point</div>
                    <div>{Number(gpoint).toLocaleString()}</div>
                </div>
                <div className='line'>
                    <div>Diamond</div>
                    <div>{Number(diamond).toLocaleString()}</div>
                </div>
                <div className='line'>
                    <div>Gold</div>
                    <div>{Number(gold).toLocaleString()}</div>
                </div>
            </div>
            <div className='btnType2'>
                <button onClick={()=>submit()}>Start</button>
            </div>
        </div>
    );
}

export default observer(Swap);