import React from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../../lang/lang';
import '../../css/pages/bclusage/shopping.scss';

const Shopping = () =>{
    return (
        <div className='shopping'>
            <div className='text'>
            <p>{lang.bclusage.ment1}</p>
            </div>
            <div className='img'>
                <img src={`${process.env.PUBLIC_URL}/img/page/bclusage/background_2${getLangImg}.png`} onError={langImgError} alt='' />
            </div>
            <div className='button'>
                <div>
                    <button onClick={() => {window.location.href = 'https://maxform.shop/'}}>Go to shop</button>
                </div>
            </div>
        </div>
    );
}

export default observer(Shopping);