import React from 'react';
import ReactDOM from 'react-dom/client';
import lang from '../lang/lang';
import route from './route';
const layerType = () => {
    let data = [];
    lang.layer.layer_list.map((x)=>{
        return (
            route.map((y) =>{
                if(x.code === y.code && y.access === 'layer'){
                    data[x.code] = {...x, ...y};
                }
                return data;
            })
        )
    })
    return data;
}

const className = 'layer';
const Layer = (type,duplication = false) => {
    const dom = document.querySelector('.App');
    const pickType = layerType().hasOwnProperty(type)?layerType()[type]:layerType().login;
    
    const layer = document.createElement("div");
    layer.className = className;
    const layerBackground = document.createElement("div");
    layerBackground.className = className + "Background";
    let layerDom = document.querySelectorAll('.'+className);
    let layerBackgroundDom = document.querySelector('.' + className + "Background");
    let duplicationNum = 0;
    let print = true;
    if(layerDom.length){
        if(duplication){
            const layerLast = layerDom[layerDom.length - 1];
            duplicationNum = parseInt(layerLast.dataset.duplication) + 1;
        }else{
            layerDom.forEach(element => {
                element.remove();
            });
        }
        layerBackgroundDom.remove();
        layerDom.forEach(element => {
            element.dataset.type === pickType.code && element.remove();
            print = element.dataset.type === pickType.code?false:print;
        });
    }
    
    if(print){
        layer.dataset.type = pickType.code;
        layer.dataset.duplication = duplicationNum;
        const root = ReactDOM.createRoot(layer);
        var element = React.createElement('layer', {  }, pickType.Component());
        root.render(element);
        dom.appendChild(layer);
        dom.appendChild(layerBackground);
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = '';
    }
}

export default Layer;