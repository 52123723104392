const Text = {
    type_path_buy : 'Buy G.Money',
    type_path_sell : 'Sell G.Money',
    type_path_swap : 'Swap',
    type_path_balance : 'My Balance',
    type_path_statement : 'Statement',
    type_path_sending : 'Sending',
    top_btn_buy : 'Buy',
    top_btn_sell : 'Sell',
    top_btn_swap : 'Exchange',
    top_btn_balance : 'Balance',
    top_btn_statement : 'Statement',
    top_btn_sending : 'Sending',
    gas_send : "To 'send' you need to recharge gas.",
    bnb : "When refilling gas, make sure it is network BNB and deposit. We are not responsible for depositing with other coins.\nOne Sending per gas refill is allowed.",
    from : 'From',
    to : 'To',
    quantity: 'Quantity',
    swap : 'Swap',
    fees : 'Total Fees',
    rate : 'Exchange Rate',
    amount : 'Exchange Amount',
    my_amount : 'My Amount',
}

export default Text;