const Text = {
    title : "Privite Information",
    welcome : "Welcome!",
    content1 : "Metamask must be connected.",
    content2 : "Convert your network to BNB.",
    content3 : "Web payments are made with Metamask extension programs.",
    content4 : "Mobile payments are made in the mining app.",
    confirm : 'Confirm',
    next : 'Go to Next',
}

export default Text;