import React from 'react';
import ReactDOM from 'react-dom/client';
import Buy from './buy';
import Sell from './sell';
import Swap from './swap';
import Balance from './balance';
import Statement from './statement';
import Sending from './sending';
import lang from '../../lang/lang';

import '../../css/pages/service/service.scss';

const Service = (type,data) => {
    const Type = {
        'buy' : {'dom':<Buy /> , 'name':lang.service.top_btn_buy , 'fullName':lang.service.type_path_buy},
        'sell' : {'dom':<Sell /> , 'name':lang.service.top_btn_sell , 'fullName':lang.service.type_path_sell},
        'swap' : {'dom':<Swap /> , 'name':lang.service.top_btn_swap , 'fullName':lang.service.type_path_swap},
        'balance' : {'dom':<Balance /> , 'name':lang.service.top_btn_balance , 'fullName':lang.service.type_path_balance},
        'statement' : {'dom':<Statement /> , 'name':lang.service.top_btn_statement , 'fullName':lang.service.type_path_statement},
        'sending' : {'dom':<Sending /> , 'name':lang.service.top_btn_sending , 'fullName':lang.service.type_path_sending}
    }
    const dom = Type[type]['dom'];
    const name = Type[type]['name'];
    const fullName = Type[type]['fullName'];
    const statement = (type) => {
        const btnClasstype = type === 'statement' ?'statement':'type';

        const btn = document.querySelector('#serviceTypeBtn');
        btn.querySelector('.action').classList.remove('action');
        btn.querySelector('.'+btnClasstype).classList.add('action');

        const path = document.querySelector('#serviceTypePath');
        path.innerHTML = type === 'statement' ?`${data.name} > ${fullName} > ${Type['statement']['name']}` : `${data.name} > ${fullName}`;

        const dom = document.querySelector('#middle_box');
        const div = document.createElement("div");
        const root = ReactDOM.createRoot(div);
        var element = React.createElement('div', null, Type[type]['dom']);
        root.render(element);
        dom.replaceChildren();
        dom.appendChild(div);
    }
    return (
        <div className='serviceEvent'>
            <div className='top'>
                <div className='path'>
                    <p id='serviceTypePath'>{data.name} > {fullName}</p>
                </div>
                <div id='serviceTypeBtn' className='button'>
                    <button className='type action' onClick={() => statement(type)}>{name}</button>
                    <button className='statement' onClick={() => statement('statement')}>{lang.service.top_btn_statement}</button>
                </div>
            </div>
            <div className='middle' id='middle_box'>
                {dom}
            </div>
        </div>
    );
}

export default Service;