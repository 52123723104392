import React from 'react';
import Print from './config/print';
import {BrowserRouter} from 'react-router-dom';
//import { decorate, observable, action } from 'mobx';
import { observer } from "mobx-react";
import './App.css';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Print />
      </BrowserRouter>
    </div>
  );
}

export default observer(App);
