import React,{useState} from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { observer } from 'mobx-react';
import Alert from '../../utils/alert';
import lang from '../../lang/lang';


const Sending = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
    const walletConnect = window.localStorage.getItem("wagmi.connected");
    const [input, setInput] = useState({
        bcl : (userData)?userData['bcl']['amount']:0,
        amount : 0,
        per:0,
        gas : (userData)?userData['gas']['amount']:0
    });
    const {bcl,amount,gas,per} = input;
    if(!user){
        Alert('error','Login is required.');
        return;
    }
    if(!walletAccount || !walletConnect)
    {
        walletAccount['state']['data'] && Alert('error',lang.alert.connect_wallet);
        walletAccount['state']['data'] && window.location.replace('/wallet');
        return;
    }
    if(!walletAccount['state']['data']['account'])
    {
        Alert('error',lang.alert.connect_wallet);
        window.location.replace('/wallet');
        return;
    }

    const select = () => {
        const selectVal = document.querySelector("#swapQuantity").value;
        let amountVal = Math.floor(bcl * selectVal / 100);

        if(Number(amountVal) > 1000){
            amountVal = 1000;
        }

        setInput((prevState)=>{return {...prevState,amount:amountVal}});
        setInput((prevState)=>{return {...prevState,per:selectVal}});
    }

    const bnbsubmit = async () => {
        const web3 = new Web3(
            window.ethereum //  메타마스크 프로바이더
            || 'http://localhost:8545'
            );
        const accounts = [walletAccount['state']['data']['account']];
        setInput((prevState)=>{return {...prevState,account:accounts[0]}});
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            alert(lang.alert.payment_bcl);
            window.location.href = "https://bitcoinlegend.page.link/?link=https://bitcoinlegend.link/?adress%3D0xB8C6ea3e9a87b200003f6dF2ae27F79C8Cf97f75%26amount%3D0.00"+userData['gasFee']['gasFee']+"%26type%3DBNB%26email%3D"+user['key']+"&apn=com.bitlegendapp&isi=1591021356&ibi=io.bitcionlegend.ios&efr=1";
        }else{
            await web3.eth.sendTransaction({
              from: accounts[0],
              to: '0xB8C6ea3e9a87b200003f6dF2ae27F79C8Cf97f75',
              value: web3.utils.toWei(userData['gasFee']['gasFee'], 'milli'),
              data: ''
            }).on('transactionHash', function(hash){
              axios.get(`https://maxform.link/api/exchange/fbcjfrl/addWaitingGasWeb.php?email=${user['key']}&address=${accounts[0]}&amount=${userData['gasFee']['gasFee']}&hash=${hash}`)
              Alert('success',lang.alert.gas_success);
            })
        }
    }
    let duplication = 0;
    const submit = () => {
        //Alert('warning',lang.alert.coming_soon);
        //return;
        let valBcl = 0;
        let valGas = 0;
        if(Number(per) === 0){
            Alert('error',lang.alert.no_percentage);
            return;
        }

        if(!duplication)
        {
            axios.get(`https://maxform.link/api/exchange/fbcjfrl/myBCL.php?email=${user['key']}`)
            .then((res) => {
                valBcl = res.data;
                axios.get(`https://maxform.link/api/exchange/fbcjfrl/myGas.php?email=${user['key']}`)
                .then((res) => {
                    valGas = res.data;
                    if(valBcl && valGas){
                        duplication = 1;
                        axios.get(`https://pay.bitcoinlegend.org/admin/api/changeSettleV2?email=${user['key']}&address=${walletAccount['state']['data']['account']}&per=${per}`)
                        .then((res) => {
                            if(res.data === 'success'){
                                axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${user['key']}`)
                                .then((res) => {
                                    if(res.data){
                                        window.sessionStorage.removeItem('userData');
                                        window.sessionStorage.setItem("userData", JSON.stringify(res.data));
                                        alert(lang.alert.payment_success);
                                    }
                                    window.location.replace('/service');
                                })
                            }else if(res.data === 'same_date'){
                                Alert('warning',lang.alert.payment_one_day);
                            }else{
                                Alert('error',lang.alert.payment_failed);
                            }
                        })
                    }else{
                        Alert('error','no Gas value Or no BCL value');
                    }
                })
                .catch((err) => {
                    Alert('warning',lang.alert.try);
                })
            })
            .catch((err) => {
                Alert('warning',lang.alert.try);
            })
        }
        else
        {
            Alert('error',lang.alert.payment_after);
        }
    }


    return (
        <div className='sending'>
            <div className='swapBoard'>
                <div className='title'><p>Complete!</p></div>
                <div className='subTitle'>Type</div>
                <div className='quantity'>
                    <div className='text'>
                        <div className='inputType1'>
                            <input value='BCL' disabled/>
                        </div>
                    </div>
                    <select id='swapQuantity' onChange={()=>select()}>
                        <option value='0'>0%</option>
                        <option value='100'>100%</option>
                        <option value='90'>90%</option>
                        <option value='80'>80%</option>
                        <option value='70'>70%</option>
                        <option value='60'>60%</option>
                        <option value='50'>50%</option>
                        <option value='40'>40%</option>
                        <option value='30'>30%</option>
                        <option value='20'>20%</option>
                        <option value='10'>10%</option>
                    </select>
                </div>
                <div className='subTitle'>Amount <p className='right'>Max 5000</p></div>
                <div className='quantity'>
                    <div className='text2'>
                        <div className='inputType1'>
                            <input value={amount} disabled/>
                        </div>
                    </div>
                </div>
                <div className='subTitle'>Sending Count</div>
                <div className='quantity'>
                    <div className='text2'>
                        <div className='inputType1'>
                            <input value={gas} disabled/>
                        </div>
                    </div>
                </div>
                <div className='box'></div>
                <div className='box'>{lang.alert.payment_one_day}<br />{lang.service.gas_send}</div>
                <div className='box'>{lang.service.bnb}</div>
                <div className='box'></div>
            </div>
            <div className='btnType2'>
                <button onClick={()=>bnbsubmit()}>BNB Gas Fee</button>
                <button onClick={()=>submit()}>Sending</button>
            </div>
        </div>
    );
}

export default observer(Sending);