import header from './ar/header';
import layer from './ar/layer';
import alert from './ar/alert';
import main from './ar/main';
import wallet from './ar/wallet';
import contact from './ar/contact';
import bclusage from './ar/bclusage';
import service from './ar/service';
import selectGame from './ar/selectGame';
import nftIntro from './ar/nftintro';

const ar = {
    header : header,
    layer : layer,
    alert : alert,
    main : main,
    wallet : wallet,
    contact : contact,
    bclusage : bclusage,
    service : service,
    selectGame : selectGame,
    nftIntro : nftIntro
}

export default ar;
