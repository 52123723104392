const Text = {
    title : "个人信息",
    welcome : "欢迎！",
    content1 : "必须连接Metamask。",
    content2 : "将您的网络转换为BNB。",
    content3 : "网页支付使用Metamask扩展程序完成。",
    content4 : "移动支付在采矿应用程序中完成。",
    confirm : '确认',
    next : '下一步',
}

export default Text;