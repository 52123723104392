import React, { useState ,useEffect } from 'react';
import { observer } from 'mobx-react';
import GoogleLogin from '../../api/google';
import Alert from '../../utils/alert';
import Layer from '../../config/layer';
import lang from '../../lang/lang';
import '../../css/pages/layer/common/type1.scss';
import '../../css/pages/layer/login.scss';

const Login = () =>{

    const warning = () => {
        Alert('warning',lang.alert.coming_soon);
    }

    const appleBtn = (res) => {
        const config = {
            client_id: 'link.bitcoinlegend', // This is the service ID we created.
            redirect_uri: "https://bitcoinlegend.link/api/apple", // As registered along with our service ID
            response_type: "code id_token",
            state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
            scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
            response_mode: "form_post",
            m: 11,
            v: "1.5.4"
          };
          const queryString = Object.entries(config).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');

          window.location.href = `https://appleid.apple.com/auth/authorize?${queryString}`;
   }
      
    return (
        <div className='login'>
            <div className='content_box'>
                <div className='title'>
                    <p>LOGIN</p>
                </div>
                <div className='loginBtn'>
                    <button onClick={() => Layer('signIn',false)}><img src={`${process.env.PUBLIC_URL}/img/icon/mailLogo_1.png`} alt=''/><div className='btnName'><div className='simple'>Sign In with </div> E-mail <div className='simple'> except for Gmail</div></div></button>
                    <button onClick={GoogleLogin}><img src={`${process.env.PUBLIC_URL}/img/icon/googleLogo_1.png`} alt=''/><div className='btnName'><div className='simple'>Sign In with </div> Google</div></button>
                    <button onClick={() => appleBtn()}><img src={`${process.env.PUBLIC_URL}/img/icon/appleLogo_1.png`} alt=''/><div className='btnName'><div className='simple'>Sign In with </div> Apple</div></button>
                </div>
                <div className='terms'>
                    <div>{lang.layer.login_content}</div>
                </div>
                <div className='etcBtn'>
                    {/* <button onClick={() => warning()}><p>Forgot your password?</p><img className='simple' src={`${process.env.PUBLIC_URL}/img/icon/question_1.png`} alt=''/></button> */}
                </div>
            </div>
            <div className='back_box' onClick={()=>Layer('login')}><img src={`${process.env.PUBLIC_URL}/img/page/layer/background_1.png`} alt=''/></div>
        </div>
    );
}

export default observer(Login);