const Text = {
    type_path_buy : 'Mua G.Money',
    type_path_sell : 'Bán G.Money',
    type_path_swap : 'Đổi',
    type_path_balance : 'Số dư của tôi',
    type_path_statement : 'Lịch sử giao dịch',
    type_path_sending : 'Gửi',
    top_btn_buy : 'Mua',
    top_btn_sell : 'Bán',
    top_btn_swap : 'Đổi',
    top_btn_balance : 'Số dư',
    top_btn_statement : 'Lịch sử',
    top_btn_sending : 'Gửi',
    gas_send : "Để 'gửi' bạn cần nạp gas.",
    bnb : "Khi nạp gas, hãy đảm bảo nạp vào mạng BNB và đúng địa chỉ. Chúng tôi không chịu trách nhiệm đối với việc nạp với các loại tiền khác.\nChỉ được gửi một lần cho mỗi lần nạp gas.",
    from : 'Từ',
    to : 'Tới',
    quantity: 'Số lượng',
    swap : 'Đổi',
    fees : 'Phí tổng cộng',
    rate : 'Tỷ giá',
    amount : 'Số tiền đổi',
    my_amount : 'Số tiền của tôi',
}

export default Text;