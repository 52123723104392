const Text = {
    type_path_buy : 'شراء G.Money',
    type_path_sell : 'بيع G.Money',
    type_path_swap : 'تبادل',
    type_path_balance : 'رصيدي',
    type_path_statement : 'كشف حساب',
    type_path_sending : 'الإرسال',
    top_btn_buy : 'شراء',
    top_btn_sell : 'بيع',
    top_btn_swap : 'تبادل',
    top_btn_balance : 'رصيد',
    top_btn_statement : 'كشف حساب',
    top_btn_sending : 'الإرسال',
    gas_send : 'يجب إعادة شحن الرسوم اللازمة لإرسال العملة.',
    bnb : 'عند إعادة شحن الرسوم اللازمة، تأكد من أنها على شبكة BNB وفي الإيداع. نحن لسنا مسؤولين عن الإيداع بعملات أخرى.\nيسمح بإرسال واحد لكل عملية إعادة شحن الرسوم.',
    from : 'من',
    to : 'إلى',
    quantity: 'الكمية',
    swap : 'تبادل',
    fees : 'إجمالي الرسوم',
    rate : 'سعر الصرف',
    amount : 'مبلغ التبادل',
    my_amount : 'مبلغي',
}

export default Text;