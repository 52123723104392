import React,{useState} from 'react';
import { observer } from 'mobx-react';
//import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Layer from '../../config/layer';
import Alert, {AlertCloseEvent} from '../../utils/alert';
import lang from '../../lang/lang';
import '../../css/pages/layer/common/type1.scss';
import '../../css/pages/layer/signIn.scss';
const emailLogin = (id,pwd) => {
    axios.post(`https://bitcoinlegend.io/api/auth/emailLogin`,{
        email : id,
        password : pwd
    })
    .then((res) => {
        if(res.data !== 'failed'){
            if(res.data.key){
                window.sessionStorage.setItem("user", JSON.stringify(res.data));
                loadMyAccount(id);
            }else{
                Alert('error',lang.alert.password_error);
            }
        }else{
            Alert('error',lang.alert.platform_id_exists);
            window.location.replace('/');
        }
    })
    .catch((err) => {
        Alert('warning',lang.alert.try);
    })
}

const loadMyAccount = (id) => {
    axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${id}`)
    .then((res) => {
        if(res.data){
            window.sessionStorage.setItem("userData", JSON.stringify(res.data));
            window.location.replace('/');
        }else{
            window.sessionStorage.removeItem('user');
            Alert('warning',lang.alert.try);
        }
    })
    .catch((err) => {
        window.sessionStorage.removeItem('user');
        Alert('warning',lang.alert.try);
    })
}

const SignIn = () =>{
    const [input, setInput] = useState({
        id:'',
        pwd:''
    });
    const {id,pwd} = input;
    const changeInput = (e) => {
        const val = e.target.value;
        setInput({
            ...input,
            [e.target.name]:val
        });
    }

    const idCheck = (id) => {
        axios.get(`https://bitcoinlegend.io/api/auth/checkEmailV3?email=${id}`)
        .then((res) => {
            let result = res.data !== 'no_email' ? res.data.provider : false;
            idCheckResult(result);
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    }
    
    const idCheckResult = (b) => {
        const inputId = document.querySelector("#input_id input");
        const inputPw = document.querySelector("#input_pw input");
        if(b)
        {
            if(b === 'email')
            {
                inputPw.parentNode.style.display = 'block';
                inputId.parentNode.style.display = 'none';
            }
            else if(b === 'google')
            {
                Alert('warning',lang.alert.different_google);
            }
            else if(b === 'apple')
            {
                Alert('warning',lang.alert.different_apple);
            }
            else
            {
                Alert('error',lang.alert.id_check_error);
            }
        }else{
            Layer('signUp',false);
        }
    }

    const nextBtn = () => {
        const inputId = document.querySelector("#input_id input");
        if(inputId.parentNode.style.display !== 'none'){
            let regex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
            if(regex.test(id)){
                idCheck(id);
            }else{
                Alert('error',lang.alert.email_format);
            }
        }else{
            if(pwd === ''){
                Alert('error',lang.alert.password_enter);
            }else{
                emailLogin(id,pwd);
            }
        } 
    }

    const privateBtn = () => {
        const btn = document.querySelector('#pwdInput');
        const img = document.querySelector('#privateImg');
        btn.type = btn.type === 'password'?'text':'password';
        img.src = btn.type === 'password'?'img/icon/privateOffLogo_1.png':'img/icon/privateOnLogo_1.png';
    }

    const enterKey = () => {
        window.event.keyCode === 13 && AlertCloseEvent();
        window.event.keyCode === 13 && nextBtn();
    }
    return (
        <div className='signIn'>
            <div className='content_box'>
                <div className='title'>
                    <p>LOGIN</p>
                </div>
                <div className='input_box'>
                    <div id='input_id'>
                        <p>E-mail</p>
                        <input type='text' name='id' onChange={changeInput} onKeyUp={()=>enterKey()}/>
                     </div>
                    <div id='input_pw'>
                        <p>Password</p>
                        <input id='pwdInput' type='password' name='pwd' onChange={changeInput} onKeyUp={()=>enterKey()}/>
                        <button onClick={() => privateBtn()}><img id='privateImg' src={`${process.env.PUBLIC_URL}/img/icon/privateOffLogo_1.png`}  alt=''/></button>
                    </div>
                </div>
                <div className='notice'>
                    <div className='title'><p>{lang.layer.signin_title}</p></div>
                    <div className='content'>
                        <p>{lang.layer.signin_content}</p>
                    </div>
                </div>
                <div className='signBtn'>
                    <button onClick={nextBtn}><img src={`${process.env.PUBLIC_URL}/img/button/btn_background_1.png`} alt=''/><p>NEXT</p></button>
                </div>
                <div className='etcBtn'>
                    {/* <button><p>Forgot your password?</p><img className='simple' src={`${process.env.PUBLIC_URL}/img/icon/question_1.png`} alt=''/></button> */}
                </div>
            </div>
            <div className='back_box' onClick={()=>Layer('signIn')}><img src={`${process.env.PUBLIC_URL}/img/page/layer/background_1.png`} alt=''/></div>
        </div>
    );
}

export default observer(SignIn);