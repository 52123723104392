import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '../../css/pages/nftrental/registration.scss';
import lendingRequest from '../../assets/lending_request.png';
import useForMyself from '../../assets/use_for_myself.png';
import defaultImg from '../../assets/temp.png';

const BASE_URL = 'https://maxform.link/api/exchange/nft'

const Registration = (props) =>{
  const [nftList, setNftList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const navigate = useNavigate();
  const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
  // const walletConnect = window.localStorage.getItem("wagmi.connected");
  const user = JSON.parse(window.sessionStorage.getItem('user'));

  const MySwal = withReactContent(Swal)

  const registMine = async () => {
    try {
      const result = await MySwal.fire({
        title: 'Do you want to register selected NFTs myself?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      })
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let url = ''
        if (user && walletAccount) {
          for (let i=0; i<checked.length; i++) {
            const url = BASE_URL + `/regist-mine.php`;
            const data = {
              email: user.key,
              account: walletAccount.state.data.account,
              token_id: checked[i]
            };

            const headers = {
              'Content-Type': 'application/x-www-form-urlencoded'
            };

            try {
              const response = await axios.post(url, data, { headers });
              console.log('Response:', response.data); // Logging the response data
              if (response.data.code == 200) {
              } else {
                Swal.fire('Please Try Again.', '', 'failed')
                return
              }
            } catch (error) {
              console.error('Error:', error);
              Swal.fire('Please Try Again.', '', 'failed')
            }
          }

          if (user && walletAccount) {
            url = BASE_URL + `/regist-list.php?email=${user.key}&account=${walletAccount.state.data.account}`
            // url = 'https://maxform.link/api/exchange/nft/regist-list.php?email=test123@test.com&account=0xtestaccount&token_id=6'
          }
          const response = await axios.get(url);
          setNftList(response.data);
        }
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }

  const regist = async () => {
    try {
      const result = await MySwal.fire({
        title: 'Do you want to register selected NFTs as a rental?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      })
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let url = ''
        if (user && walletAccount) {
          for (let i=0; i<checked.length; i++) {
            const url = BASE_URL + `/regist.php`;
            const data = {
              email: user.key,
              account: walletAccount.state.data.account,
              token_id: checked[i]
            };
  
            const headers = {
              'Content-Type': 'application/x-www-form-urlencoded'
            };
  
            try {
              const response = await axios.post(url, data, { headers });
              console.log('Response:', response.data); // Logging the response data
              if (response.data.code == 200) {
              } else {
                Swal.fire('Please Try Again.', '', 'failed')
                return
              }
            } catch (error) {
              console.error('Error:', error);
              Swal.fire('Please Try Again.', '', 'failed')
            }
          }

          if (user && walletAccount) {
            url = BASE_URL + `/regist-list.php?email=${user.key}&account=${walletAccount.state.data.account}`
          }
          const response = await axios.get(url);
          setNftList(response.data);
        }
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }

  const handleClickButton = e => {
    const { name } = e.target;
    if (name === 'rental') {
      navigate('/nft/rental')
    } else if (name === 'mynft') {
      navigate('/nft/mynft')
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checkedAll) => {
    if(checkedAll) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];
      nftList.forEach((el) => idArray.push(el.token_id));
      setChecked(idArray);
    }
    else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setChecked([]);
    }
  }

  // 체크박스 단일 선택
  const handleSingleCheck = (checkedItem, id) => {
    if (checkedItem) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setChecked(prev => [...prev, id]);
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setChecked(checked.filter((el) => el !== id));
    }
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        setError(null);
        setNftList(null);
        setLoading(true);

        let url = ''
        if (user && walletAccount) {
          url = BASE_URL + `/regist-list.php?email=${user.key}&account=${walletAccount.state.data.account}`
          // url = 'https://maxform.link/api/exchange/nft/regist-list.php?email=test123@test.com&account=0xtestaccount&token_id=6'
        }
        const response = await axios.get(url);
        setNftList(response.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (nftList.length == 0 && user) {
      fetchList();
    }
  }, []);

  if (loading) return <div>로딩중..</div>;
  if (error) return <div>에러가 발생했습니다</div>;
  if (!nftList) return null;

  return (
      <div className='registration'>
        <div className='container'>
          <button
              className='button' onClick={handleClickButton} name={'rental'}>{'NFT\nRental'}</button>
          <button
              className='button type1'>{'NFT\nRegistration'}</button>
          <button
              className='button' onClick={handleClickButton} name={'mynft'}>{'My\nNFT'}</button>
        </div>
        {(user && walletAccount) ?
        <div className='content'>
          <div className='title'>NFT Rental > NFT Register</div>
          <div className='btn-container'>
            <img className='btn-img' src={lendingRequest} onClick={regist}/>
            <img className='btn-img' src={useForMyself}  onClick={registMine}/>
          </div>
          {/*<div className='allselect'>*/}
          {/*  <input name='selectall' value={'all'} type="checkbox" onChange={(e) => handleAllCheck(e.target.checked)}/>*/}
          {/*  <div className='text'>All select</div>*/}
          {/*</div>*/}
          <div className="list-container">
            {nftList.map((item, index) => (
                <div className='nft-box' key={index}>
                  <div className='checkboxes'>
                    <input name='nft' id={item.token_id} type="checkbox" onChange={(e) => handleSingleCheck(e.target.checked, item.token_id)}
                           checked={checked.includes(item.token_id) ? true : false}/>
                  </div>
                  <img className='nft-img' src={
                    (item.token_id) ?
                    '../../img/nft/' + (parseInt(item.token_id, 10) +1).toString() +'.png'
                    : {defaultImg}
                    }
                  />
                </div>
            ))}
          </div>
        </div>
        : <div className='title'>Please Login in and Access Wallet</div>
        }
      </div>
  );
}

export default Registration;