import React,{useState,useEffect} from 'react';
import { observer } from 'mobx-react';
import {getEventListeners} from 'geteventlisteners';
import Slide from '../utils/slide';
import Alert from '../utils/alert';
import lang from '../lang/lang';
import '../css/pages/selectGame.scss';
const warning = () => {
    Alert('warning',lang.alert.coming_soon);
}

const href = (href = null) => {
    if(href !== null) window.location.href = href;
    else warning();
}
const change = (session = null) => {
    (document.querySelector('#gameGoogleStore').getEventListeners().click) &&
        document.querySelector('#gameGoogleStore').removeEventListener('click',
            document.querySelector('#gameGoogleStore').getEventListeners().click[0].listener
        );

    (document.querySelector('#gameAppleStore').getEventListeners().click) &&
        document.querySelector('#gameAppleStore').removeEventListener('click',
            document.querySelector('#gameAppleStore').getEventListeners().click[0].listener
        );
    
    if(session !== null)
    {
        document.querySelector('#gameGoogleStore').addEventListener('click',()=>href(session.playURL));
        document.querySelector('#gameAppleStore').addEventListener('click',()=>href(session.appleURL));
        document.querySelector('#gameInfoYoutube').src = session.youURL;
        document.querySelector('#gameImgLogo').src = session.imgURL;
    }
    else
    {
        document.querySelector('#gameGoogleStore').addEventListener('click',()=>href(null));
        document.querySelector('#gameAppleStore').addEventListener('click',()=>href(null));
        document.querySelector('#gameInfoYoutube').src = "https://www.youtube.com/embed/TJgIVSCwCQg";
        document.querySelector('#gameImgLogo').src = `${process.env.PUBLIC_URL}img/icon/basic.png`;
    }
}

const SelectGame = () => {
    const sessionGame = JSON.parse(window.sessionStorage.getItem('game'));
    let session = sessionGame[0];
    const [input, setInput] = useState({
        gameNum : 1,
        gameName : session.name,
        gameContent : "( "+session.content+" )"
    });
    const {gameNum,gameName,gameContent} = input;
    
    const ordinalNumber = ['st','nd','rd','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','st','nd','rd'];
    const slideClick = (code=null) => {
        if(code !== null)
        {
            let num = 0;
            sessionGame.forEach((i,j) => {
                session = i.code === code ? i : session ;
                num = i.code === code ? parseInt(j) + 1 : num;
            });
            setInput((prevState)=>{return {...prevState,gameNum:num}});
            setInput((prevState)=>{return {...prevState,gameName:session.name}});
            setInput((prevState)=>{return {...prevState,gameContent:"( "+session.content+" )"}});
            change(session);
        }
        else
        {
            setInput((prevState)=>{return {...prevState,gameNum:'0'}});
            setInput((prevState)=>{return {...prevState,gameName:'Soon'}});
            setInput((prevState)=>{return {...prevState,gameContent:'( Coming Soon )'}});
            change();
        }
    }
    useEffect(() => change(session), [])
    
    
    let data = [];
    sessionGame.forEach((i,j) => {
        data[j] = {
            img : i.imgURL,
            code : i.code,
            name : j+1+""+ordinalNumber[j]+" Game",
            click : slideClick
        }
    });
    for(var j=2;j<8;j++){
        data[j] = {
            img : `${process.env.PUBLIC_URL}img/icon/basic.png`,
            name : 'soon',
            link : '/selectGame?game=',
            click : slideClick
        }
    }
    return (
        <div className='selectGame'>
            <div className='title type1'>
                <p>Game Select</p>
            </div>
            <div className='slide_box' id='slide'>
                {Slide(data)}
            </div>
            <div className='game_box'>
                <div>
                    <div className='type1'><img id='gameImgLogo' src={session.imgURL} alt=''/></div>
                    <div className='type2'>
                        <div><p name='gameNum'>{gameNum}</p>{ordinalNumber[gameNum-1]} Game</div><br />
                        <div><p name='gameName'>{gameName}</p><br />{gameContent}</div>
                    </div>
                    <div className='type3'>
                        <div>
                            <button id='gameGoogleStore'>
                                <img src={`${process.env.PUBLIC_URL}/img/button/store_google_1.png`} alt='' />
                            </button>
                        </div>
                        <div>
                            <button id='gameAppleStore'>
                                <img src={`${process.env.PUBLIC_URL}/img/button/store_apple_1.png`} alt='' />
                            </button>
                        </div>
                    </div>
                    <div className='type3'>
                        <button onClick={() =>{window.location.replace('/service');}}>{lang.selectGame.button}</button>
                    </div>
                </div>
            </div>
            <div className='video_box'>
                <div className='text'>({lang.selectGame.ment1})</div>
                <div className='video'>
                    <img src={`${process.env.PUBLIC_URL}/img/page/main/background_2.png`} alt='' />
                    <iframe
                    id='gameInfoYoutube'
                    title="메인 광고">
                    </iframe>
                </div>
                <div className='title'>{lang.selectGame.ment2}</div>
            </div>
        </div>
    );
}
export default observer(SelectGame);