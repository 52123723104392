import styled from "styled-components";

function Pagination({ total, limit, page, setPage }) {
  const numPages = Math.ceil(total / limit);
  let pageGroup = Math.ceil(page / 10);
  let groupFrist = ((pageGroup - 1) * 10) + 1;
  let groupLast = pageGroup * 10;

  let arr = new Array().fill();
  for(let i=groupFrist; i<=groupLast; i++) {
    if (i <= numPages) {
      arr[i] = pageGroup;
    }
  }

  return (
    <>
      <Nav>
        <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
          &lt;
        </Button>
        {arr
          .map((_, i) => (
            <Button
              key={i}
              onClick={() => setPage(i)}
              aria-current={page === i? "page" : null}
            >
              {i}
            </Button>
          ))}
        <Button onClick={() => setPage(page + 1)} disabled={page === numPages}>
          &gt;
        </Button>
      </Nav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 16px;
`;

const Button = styled.button`
  border: none;
  border-radius: 1vmin;
  padding: 1vmin;
  margin: 0;
  color: black;
  font-size: 2vmin;

  &:hover {
    cursor: pointer;
  }

  &[aria-current] {
    border: 2px solid #F69032;
    font-weight: bold;
    cursor: revert;
    transform: revert;
  }
`;

export default Pagination;