import React from 'react';
import { observer } from 'mobx-react';
import Alert from '../utils/alert';
import lang from '../lang/lang';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal,Web3Button  } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom } from 'wagmi/chains'
import '../css/pages/wallet.scss';


const chains = [mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom]
const projectId = 'af08c6e0d4815705e1e4cdf18d577e8e'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)


const checkboxCheck = () => {
    const input = document.querySelector('#walletConfirm');
    return input.checked;
}

const nextBtn = () => {
    const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
    const walletConnect = window.localStorage.getItem("wagmi.connected");
    if(!walletAccount || !walletConnect)
    {
        !walletAccount['state']['data']['account'] && Alert('error',lang.alert.connect_wallet);
        return;
    }
    if(checkboxCheck()){
        window.location.replace('/service');
    }else{
        Alert('error',lang.alert.no_check);
    }
}

const Wallet = () => {
    return (
        <div className='wallet'>
            <div className='title type1'>
                <p>{lang.wallet.title}</p>
            </div>
            <div className='content_box type1'>
                <div className='title'>
                    <p>{lang.wallet.welcome}</p>
                </div>
                <div>
  
                <WagmiConfig client={wagmiClient}>
                <Web3Button />
                </WagmiConfig>
                <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
                 </div>
                <div className='text type1'>
                    <p>{lang.wallet.content1}</p>
                    <p>{lang.wallet.content2}</p>
                    <p>{lang.wallet.content3}</p>
                    <p>{lang.wallet.content4}</p>
                </div>
                <div className='confirm'>
                    <input id='walletConfirm' type='checkbox' defaultChecked={true}/>
                    <p>{lang.wallet.confirm}</p>
                </div>
            </div>
            <div className='button_box type1'>
                <button onClick={()=>nextBtn()}><p>{lang.wallet.next}</p></button>
            </div>
        </div>
    );
}

export default observer(Wallet);
