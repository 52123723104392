import React from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../lang/lang';
import '../css/pages/main.scss';

const Main = () =>{
    //alert("It's out of service");
    return (
        <div className='main'>
            <div className='back_box1'>
                <img src={`${process.env.PUBLIC_URL}/img/page/main/background_1${getLangImg}.png`} onError={langImgError} alt=''/>
            </div>
            <div className='back_box2 type1'>
                <img src={`${process.env.PUBLIC_URL}/img/page/main/background_2.png`} alt='' />
                <div className='video_box'>
                    <iframe
                    src="https://www.youtube.com/embed/TJgIVSCwCQg"
                    title="메인 광고">
                    </iframe>
                </div>
            </div>
            <div className='back_box3 type1'>
                <div className='interval'></div>
            </div>
            <div className='back_box4 type1'>
                <div className='button_box type1'>
                    <button>
                        <img src={`${process.env.PUBLIC_URL}/img/button/about_1${getLangImg}.png`} onError={langImgError} alt='' />
                    </button>
                </div>
                <div className='title type1'>{lang.main.about_1_title}</div>
                <div className='content'>{lang.main.about_1_content}
                </div>
            </div>
            <div className='back_box3'>
                <div className='interval'></div>
            </div>
            <div className='back_box4 type1'>
                <div className='background'><img src={`${process.env.PUBLIC_URL}/img/page/main/background_3.png`} alt=''/></div>
                <div className='button_box type2'>
                    <button>
                        <img src={`${process.env.PUBLIC_URL}/img/button/about_1${getLangImg}.png`} onError={langImgError} alt='' />
                    </button>
                </div>
                <div className='title type2'>
                    {lang.main.about_2_title}
                </div>
                <div className='back_box1'>
                    <img src={`${process.env.PUBLIC_URL}/img/page/main/img_1${getLangImg}.png`} onError={langImgError} alt='' />
                </div>
                <div className='content_box'>
                    <div className='title type1 type3'>
                        {lang.main.app_button_ment}
                    </div>
                    <div className='content'>
                        <div className='button_box type3'>
                            <button onClick={()=>{window.location.href='https://play.google.com/store/apps/details?id=com.bitlegendapp'}}>
                                <img src={`${process.env.PUBLIC_URL}/img/button/store_google_1.png`} alt='' />
                            </button>
                        </div>
                        <div className='button_box type3'>
                            <button onClick={()=>{window.location.href='https://apps.apple.com/us/app/bitcoinlegend/id1591021356'}}>
                                <img src={`${process.env.PUBLIC_URL}/img/button/store_apple_1.png`} alt='' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='back_box3 type2'>
                <div className='interval'></div>
            </div>
        </div>
    )
}

export default observer(Main);