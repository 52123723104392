const Text = {
    type_path_buy : '购买 G.Money',
    type_path_sell : '出售 G.Money',
    type_path_swap : '兑换',
    type_path_balance : '我的余额',
    type_path_statement : '交易记录',
    type_path_sending : '发送',
    top_btn_buy : '购买',
    top_btn_sell : '出售',
    top_btn_swap : '兑换',
    top_btn_balance : '余额',
    top_btn_statement : '交易记录',
    top_btn_sending : '发送',
    gas_send : '发送操作需要充值 gas。',
    bnb : '请确保充值 gas 时选择 BNB 网络和地址。我们不负责其他币种的充值。\n每次充值 gas 只能发送一次。',
    from : '从',
    to : '到',
    quantity: '数量',
    swap : '兑换',
    fees : '手续费',
    rate : '汇率',
    amount : '兑换金额',
    my_amount : '我的金额',
}

export default Text;