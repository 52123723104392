import React from 'react';
import { observer } from 'mobx-react';
import {select} from '../bclusage';
import '../../css/pages/bclusage/game.scss';

const Game = () =>{
    const sessionGame = JSON.parse(window.sessionStorage.getItem('game'));
    return (
        <div className='game'>
            {
                sessionGame.map((i,j)=>{
                    var src = i.imgURL;
                    return (
                        <div key={j}>
                            <div onClick={()=>select(`gamePick`,i.code)}>
                                <div className='img'><img src={src} alt=''/></div>
                                <div className='name'>{i.name}</div>
                            </div>
                        </div>
                    );
                })
            }
            <div>
                <div onClick={()=>select('gamePick')}>
                    <div className='img'><img src={`${process.env.PUBLIC_URL}img/icon/basic.png`} alt=''/></div>
                    <div className='name'>Soon</div>
                </div>
            </div>
            <div>
                <div onClick={()=>select('gamePick')}>
                    <div className='img'><img src={`${process.env.PUBLIC_URL}img/icon/basic.png`} alt=''/></div>
                    <div className='name'>Soon</div>
                </div>
            </div>
            <div>
                <div onClick={()=>select('gamePick')}>
                    <div className='img'><img src={`${process.env.PUBLIC_URL}img/icon/basic.png`} alt=''/></div>
                    <div className='name'>Soon</div>
                </div>
            </div>
            <div>
                <div onClick={()=>select('gamePick')}>
                    <div className='img'><img src={`${process.env.PUBLIC_URL}img/icon/basic.png`} alt=''/></div>
                    <div className='name'>Soon</div>
                </div>
            </div>
        </div>
    );
}

export default observer(Game);