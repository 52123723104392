const Text = {
    layer_list : [
        {code: 'login', title: 'ĐĂNG NHẬP'},
        {code: 'signIn', title: 'ĐĂNG NHẬP'},
        {code: 'signUp', title: 'ĐĂNG KÝ'},
        {code: 'profile', title: 'HỒ SƠ CÁ NHÂN'}
    ],
    login_content : "Công ty của chúng tôi vận hành các trò chơi NFT,\ntrao đổi tiền game và ứng dụng khai thác BCL.\nChúng tôi tích hợp chúng.\nVì vậy hãy kiểm tra và đăng ký.\nVà nếu bạn thay đổi nó,\nđiều đó sẽ thay đổi toàn bộ,\nvì vậy hãy tham khảo.",
    signin_title : "Thông báo",
    signin_content : "Công ty của chúng tôi vận hành các trò chơi,\ntrao đổi tiền tệ và ứng dụng khai thác BCL.",
}

export default Text;