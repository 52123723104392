const Text = {
    title : "معلومات خاصة",
    welcome : "مرحبا!",
    content1 : "يجب توصيل Metamask.",
    content2 : "تحويل شبكتك إلى BNB.",
    content3 : "يتم إجراء المدفوعات عبر برامج تمديد Metamask.",
    content4 : "يتم إجراء المدفوعات عبر تطبيق التعدين المحمول.",
    confirm : 'تأكيد',
    next : 'التالي',
}

export default Text;