const Text = {
    layer_list : [
        {code: 'login', title: 'LOGIN'},
        {code: 'signIn', title: 'LOGIN'},
        {code: 'signUp', title: 'SIGN UP'},
        {code: 'profile', title: 'MY PROFILE'}
    ],
    login_content : "Our company operates NFT games,\nGame money exchange, and BCL mining app.\nWe integrate this.\nSo check it out and sign up.\nAnd if you change it,\nit will be changed altogether,\nso please refer to it.",
    signin_title : "Notice",
    signin_content : "Our company operates games,\ncurrency exchange, and BCL mining apps.",
}

export default Text;