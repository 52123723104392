import React from 'react';
import Main from '../pages/main';
import Wallet from '../pages/wallet';
import SelectGame from '../pages/selectGame';
import Service from '../pages/service';
import BCLUsage from '../pages/bclusage';
import Contact from '../pages/contact';
import NftRental from '../pages/nftrental';
import Rental from '../pages/nftrental/rental';
import PayForRental from '../pages/nftrental/payforrental';
import Registration from '../pages/nftrental/registration';
import MyNft from '../pages/nftrental/mynft';
import Ranking from '../pages/nftrental/ranking';
//import Test from '../pages/test';
import Login from '../pages/layer/login';
import SignIn from '../pages/layer/signIn';
import SignUp from '../pages/layer/signUp';
import Profile from '../pages/layer/profile';

const route = [
    {
        path: '/',
        access: 'public',
        Component: () => <Main />
    },
    {
        path: '/wallet',
        access: 'public',
        Component: () => <Wallet />
    },
    {
        path: '/selectGame',
        access: 'public',
        Component: () => <SelectGame />
    },
    {
        path: '/service',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/sevice/buyGmoney',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/sevice/sellGmoney',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/sevice/balance',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/sevice/swap',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/sevice/sending',
        access: 'public',
        Component: () => <Service />
    },
    {
        path: '/nft',
        access: 'public',
        Component: () => <NftRental />
    },
    {
        path: '/nft/ranking',
        access: 'public',
        Component: () => <Ranking />
    },
    {
        path: '/nft/rental',
        access: 'public',
        Component: () => <Rental />
    },
    {
        path: '/nft/rental/pay',
        access: 'public',
        Component: () => <PayForRental />
    },
    {
        path: '/nft/registration',
        access: 'public',
        Component: () => <Registration />
    },
    {
        path: '/nft/mynft',
        access: 'public',
        Component: () => <MyNft />
    },
    {
        path: '/bclUsage',
        access: 'public',
        Component: () => <BCLUsage />
    },
    {
        path: '/contact',
        access: 'public',
        Component: () => <Contact />
    },
     //{
     //    path: '/test',
     //    access: 'public',
     //    Component: () => <Test />
     //},
    {
        path: '/layer/login',
        access: 'layer',
        code : 'login',
        Component: () => <Login />
    },
    {
        path: '/layer/signIn',
        access: 'layer',
        code : 'signIn',
        Component: () => <SignIn />
    },
    {
        path: '/layer/signUp',
        access: 'layer',
        code : 'signUp',
        Component: () => <SignUp />
    },
    {
        path: '/layer/profile',
        access: 'layer',
        code : 'profile',
        Component: () => <Profile />
    },
    {
        path: '*',
        access: 'public',
        Component: () => <Main />
    }
];

export default route;
