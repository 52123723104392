const Text = {
  benefit : "Benefit",
  title_head : "NFT 좋은건 알겠는데, 무슨 혜택이있죠?",
  description_head : 'NFT 렌탈, 레퍼럴 등 다양한 혜택이 있습니다.\n원하는 수익을 택하여 활용해보세요.',
  title_2 : "NFT를 렌탈해주고\n혜택도 받고 추천도 해주고!",
  class_benefit: '등급\n헤택',
  description_2_1 : 'NFT 등급별 희귀도에 따라 차별 혜택 제공\nNFT를 렌트 해주는 렌더와 렌트 하는 게이머 모두에게 혜택 제공',
  description_2_2 : '4단계로 구분, 채굴 속도, G.Point 비율, NFT 랜덤 제공 등',
  description_2_3 : `본인이 게임을 하거나 렌트 해주거나! 이제 선택하여 혜택받자!`,
  title_3: 'NFT 렌탈 서비스',
  subtitle_3_1: 'NFT 등급',
  subtitle_3_2: '혜택',
  description_title_3_1: `등급별 희귀도`,
  explain_3_1: `*희귀도 기준%`,
  description_title_3_2: `NFT 소유자 혜택`,
  cell_3_1: `혜택종류`,
  cell_3_2: `등급`,
  cell_3_3: `게이머`,
  cell_3_4: `렌더`,
  cell_3_5: `NFT 에어드랍`,
  description_title_3_3: `NFT렌더와 게이머 모두에게 혜택 부여`,
  description_3_3_1:`NFT를 게이머에게 빌려준 렌더는 2%\nNFT를 렌트받은 게이머는 8%\n동시 지급하여 모두에게 혜택을 부여합니다.`,
  explain_3_3: '*Common 기준',
  description_title_3_4: `혜택방법`,
  choice_a_desc: `본인이 소지하여 게임을 하여\nG.P를 받습니다.`,
  choice_a_desc_2: `212,400G\n혜택받기`,
  choice_b_desc: `게이머에게 렌탈비를 받고\n빌려주어 G.P를 받습니다.`,
  choice_b_desc_2: `렌탈비\n받기`,
  choice_b_desc_3: `G.Point\n받기`,
  title_4: 'NFT 렌탈 수익률',
  subtitle_4: `렌탈수익표`,
  subtitle_4_1: '게임을 하였을때 얻는 수익과 NFT를 렌탈했을때 얻는 수익 비교',
  subtitle_4_2: '희귀도에 따른 렌탈 비용',
  profit: `수익`,
  rental_cost: `렌탈\n비용`,
  title_4_2: 'NFT 렌탈비용 시스템',
  subtitle_4_4: '10,000G.P (NFT 렌탈비용 / 30일 기준)',
  description_4_4_1: '만약 게이머가 임대료로 10,000G.P를 지불한다면,\n당신은 하루 8시간 기준으로 월 132,000G을 얻을 수 있습니다.\n',
  description_4_4_2: '게이머는 10,000G.P를 지불합니다',
  operation_cost_title: `4,000G.P(중개 운영비용)`,
  operation_cost_desc: `한달을 더 연장 할 경우\n장기간 혜택으로 4,000G.P를\n일정한 날짜에 게이머에게\n페이백 해줍니다.`,  
  rent_cost_title: `6,000G.P(렌탈비용)`,
  rent_cost_desc: `게이머에게 6,000G.P를\n받고 30일을 렌탈해줍니다.`,
  explain_4: '*혜택 정산은 신청한 날로부터 30일 계산하며, 다음달 25일에 지급됩니다.',
  title_foot : 'NFT',
  description_foot : `나만의 가상세계, 쉽고 재밌게 시작하자!`,
  btn_foot : '자세히 보기',
}

export default Text;