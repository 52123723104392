import React from 'react';
import ReactDOM from 'react-dom/client';
import { observer } from 'mobx-react';

import bclusage from './bclusage/bclusage';

import '../css/pages/bclusage.scss';

const line = (type) => {
    type = type === 'gamePick' ? 'game' : type;
    const dom = document.querySelectorAll('.line');
    dom.forEach((i)=>{
        i.dataset.line === type 
        ? i.classList.add('check')
        : i.classList.remove('check');
    });
}

const select = (type,code) => {
    const div = document.createElement("div");
    const root = ReactDOM.createRoot(div);
    var element = React.createElement('div', {  }, bclusage[type]);
    root.render(element);
    
    line(type);
    const dom = document.querySelector('#usageContent');
    dom.dataset.code = code;
    dom.replaceChildren();
    dom.appendChild(div);
}

const Usage = () =>{
    return (
        <div className='usage'>
            <div className='title type1'>
                <p>BCL Usage</p>
            </div>
            <div className='select_box type1'>
                <div className='line check' onClick={()=>select('game')} data-line='game'>Game</div>
                <div className='line' onClick={()=>select('shopping')} data-line='shopping'>Shopping</div>
                <div className='line' onClick={()=>select('minning')} data-line='minning'>Minning</div>
            </div>
            <div className='content_box type1' id='usageContent'>
                {bclusage['game']}
            </div>
        </div>
    );
}

export {select};
export default observer(Usage);