import React from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../lang/lang';
import {
	ResponsiveContainer,
    Line,
    LineChart,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
} from 'recharts';
import '../css/pages/nftrental/nftrental.scss';
import spine from '../assets/spine3.png';
import swordman from '../assets/sword.png';
import heros from '../assets/heros.png';
import icon from '../assets/user-icon.png';
import check from '../assets/check.png';
import rentgrade from '../assets/rentgrade.png';
import nftbenefit from '../assets/nftbenefit.png';
import benefitCircle from '../assets/benefit_circles.png';
import rentProgram from '../assets/rent_program.png';
import jewelBox from '../assets/jewelbox.png';
import jewels from '../assets/jewels.png';
import golds from '../assets/golds.png';

const Nftrental = () =>{
    const chartData = [
        {
            name: "10%",
            'G.Point': 0,
        },
        {
            name: "12.5%",
            'G.Point': 50000,
        },
        {
            name: "20%",
            'G.Point': 132000,
        },
        {
            name: "25%",
            'G.Point': 212400,
        },
        {
            name: "",
            'G.Point': 312400,
        },
    ];

    return (
        <div className='nftrental'>
            <div className='back_box1'>
                <div className='text_box'>
                    <div className='benefit'>{lang.nftIntro.benefit}</div>
                    <div className='title'>{lang.nftIntro.title_head}</div>
                    <div className='description'>{lang.nftIntro.description_head}</div>
                </div>
                <div className='character_box'>
                    <img className='character type1' src={swordman} alt=''/>
                    <img className='character type2' src={spine} alt=''/>
                </div>
            </div>
            <div className='type1'>
                <div className='icon_circle'>
                    <img className='user_icon' src={icon}/>
                </div>
            </div>
            <div className='back_box2'>
                <div className='text_box'>
                    <div className='title'>{lang.nftIntro.title_2}</div>
                </div>
                <div className='services_wrapper'>
                    <div className='services'>Rental</div>
                    <div className='services'>NFT</div>
                    <div className='services'>Game</div>
                </div>
                {/* <div className='hero_box'> */}
                    <img className='hero' src={heros} alt=''/>
                {/* </div> */}
                <div className='description_box'>
                    <div className='description type0'>
                        <div className='description type2'>
                            {lang.nftIntro.class_benefit}
                        </div>
                        <div className='description type3'>
                            {lang.nftIntro.description_2_1}
                        </div>
                    </div>
                    <div className='description type1'>
                        <div className='description type4'>
                            <img className='img' src={check}/>
                            <br/>
                            <img className='img' src={check}/>
                        </div>
                        <div className='description type5'>
                            <div className='description type6'>
                                {lang.nftIntro.description_2_2}
                            </div>
                            <div className='description type6'>
                                {lang.nftIntro.description_2_3}
                            </div>
                        </div>
                    </div>


                    {/*<div className='back_box2 type1'>*/}
                    {/*    <img src={`${process.env.PUBLIC_URL}/img/page/main/background_2.png`} alt='' />*/}
                    {/*    <div className='video_box'>*/}
                    {/*        <iframe*/}
                    {/*            src="https://www.youtube.com/embed/TJgIVSCwCQg"*/}
                    {/*            title="메인 광고">*/}
                    {/*        </iframe>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>


            </div>
            <div className='back_box3'>
                <div className='text_box'>
                    <div className='title'>{lang.nftIntro.title_3}</div>
                </div>
                <div className='subtitle'>{lang.nftIntro.subtitle_3_1}</div>
                <div className='content-box'>
                    <div className='title-box'>
                        <div className='number'>1.</div>
                        <div className='description'>{lang.nftIntro.description_title_3_1}</div>
                    </div>
                    <img className='img' src={rentgrade}></img>
                    <div className='explain type2'>{lang.nftIntro.explain_3_1}</div>
                    <div className='title-box'>
                        <div className='number'>2.</div>
                        <div className='description'>{lang.nftIntro.description_title_3_2}</div>
                    </div>
                    <div className='prob'>
                        <div className='line'>
                            <div className='line-element type0'>
                                <div className='line-element type6'>{lang.nftIntro.rate}</div>
                                <div className='line-element type7'>{lang.nftIntro.benefit_type}</div>
                            </div>
                            <div className='line-element type1'>G.Point</div>
                            <div className='line-element type2'>{lang.nftIntro.cell_3_5}</div>
                        </div>
                        <div className='line'>
                            <div className='line-element type3'>{'Super Rare'}</div>
                            <div className='line-element type5 border-shadow-left-white'>{lang.nftIntro.cell_3_3 + ' 24%/\n' + lang.nftIntro.cell_3_4 + ' 6%(G.P:30%)'}</div>
                            <div className='line-element type5'>{'3ea'}</div>
                        </div>
                        <div className='line'>
                            <div className='line-element type3'>{'Rare'}</div>
                            <div className='line-element type4 border-shadow-left-gray'>{lang.nftIntro.cell_3_3 + ' 16%/\n' + lang.nftIntro.cell_3_4 + ' 4%(G.P:20%)'}</div>
                            <div className='line-element type4'>{'1ea'}</div>
                        </div>
                        <div className='line'>
                            <div className='line-element type3'>{'Uncommon'}</div>
                            <div className='line-element type5 border-shadow-left-white'>{lang.nftIntro.cell_3_3 + ' 12%/\n' + lang.nftIntro.cell_3_4 + ' 3%(G.P:15%)'}</div>
                            <div className='line-element type5'>{'-'}</div>
                        </div>
                        <div className='line'>
                            <div className='line-element type3'>{'Common'}</div>
                            <div className='line-element type4 border-shadow-left-gray'>{lang.nftIntro.cell_3_3 + ' 8%/\n' + lang.nftIntro.cell_3_4 + ' 2%(G.P:10%)'}</div>
                            <div className='line-element type4'>{'-'}</div>
                        </div>
                    </div>
                </div>
                <div className='subtitle'>{lang.nftIntro.subtitle_3_2}</div>
                <div className='content-box'>
                    <div className='title-box'>
                        <div className='number'>3.</div>
                        <div className='description'>{lang.nftIntro.description_title_3_3}</div>
                    </div>
                    <div className='explain type1'>{lang.nftIntro.description_3_3_1}</div>
                    <img className='img type1' src={benefitCircle}></img>
                    <div className='explain type2'>{lang.nftIntro.explain_3_3}</div>
                    <div className='title-box'>
                        <div className='number'>4.</div>
                        <div className='description'>{lang.nftIntro.description_title_3_4}</div>
                    </div>
                    <div className='choice-box'>
                        <div className='choice'>
                            <div className='choice desc'>Choice A</div>
                            <div className='content-box'>
                                <div className='explain'>{lang.nftIntro.choice_a_desc}</div>
                                <div className='img-content'>
                                    <img className='img type2' src={jewelBox}></img>
                                    <div className='explain type3'>{lang.nftIntro.choice_a_desc_2}</div>
                                </div>
                            </div>
                        </div>
                        <div className='choice'>
                            <div className='choice desc'>Choice B</div>
                            <div className='content-box'>
                                <div className='explain'>{lang.nftIntro.choice_b_desc}</div>
                                <div className='img-content'>
                                    <div className='img-box'>
                                        <img className='img type3' src={golds}></img>
                                        <div className='explain type4'>{'30BCL'}</div>
                                    </div>
                                    <div className='plus'>+</div>
                                    <div className='img-box'>
                                        <img className='img type4' src={jewels}></img>
                                        <div className='explain type4'>{'2%'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='back_box4'>
                <div className='text_box'>
                    <div className='title'>{lang.nftIntro.title_4}</div>
                </div>
                <div className='subtitle'>{lang.nftIntro.subtitle_4}</div>
                <div className='content-box'>
                    <div className='title-box'>
                        <div className='number'>1.</div>
                        <div className='description'>{lang.nftIntro.subtitle_4_1}</div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">{lang.nftIntro.classification}</th>
                                <th scope="col">{lang.nftIntro.basicProfit}</th>
                                <th scope="col">{lang.nftIntro.pointReward}</th>
                                <th scope="col">{lang.nftIntro.pointSwap}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Super-rare</th>
                                <td>{'4,000G'}</td>
                                <td>{'30%'}</td>
                                <td>{'1,200G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Rare</th>
                                <td>{'4,000G'}</td>
                                <td>{'20%'}</td>
                                <td>{'800G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Uncommon</th>
                                <td>{'4,000G'}</td>
                                <td>{'15%'}</td>
                                <td>{'600G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Common</th>
                                <td>{'4,000G'}</td>
                                <td>{'10%'}</td>
                                <td>{'400G'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">{lang.nftIntro.classification}</th>
                                <th scope="col">{lang.nftIntro.pointSwap}}</th>
                                <th scope="col">{lang.nftIntro.pointBenefit}</th>
                                <th scope="col">{lang.nftIntro.pointTotalBenefit}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Super-rare</th>
                                <td>{'1,880G'}</td>
                                <td>{'7,080G'}</td>
                                <td>{'212,400G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Rare</th>
                                <td>{'1,520G'}</td>
                                <td>{'6,320G'}</td>
                                <td>{'189,600G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Uncommon</th>
                                <td>{'1,160G'}</td>
                                <td>{'5,760G'}</td>
                                <td>{'172,800G'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Common</th>
                                <td>{'-'}</td>
                                <td>{'4,400G'}</td>
                                <td>{'132,000G'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='title-box'>
                        <div className='number'>2.</div>
                        <div className='description'>{lang.nftIntro.subtitle_4_2}</div>
                    </div>
                    <div className='graph-box'>
                        <ResponsiveContainer width={'100%'} height={'100%'} margin={'0'}>
                            <LineChart data={chartData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Line type="linear" dataKey="G.Point" stroke="#2F1960" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='subtitle'>{lang.nftIntro.title_4_2}</div>
                <div className='content-box'>
                    <div className='title-box'>
                        <div className='number'>3.</div>
                        <div className='description'>{lang.nftIntro.subtitle_4_4}</div>
                    </div>
                    <div className='explain type1'>{lang.nftIntro.description_4_4_1}</div>
                    <div className='explain type1'>{lang.nftIntro.description_4_4_2}</div>
                    <img className='img' src={rentProgram}></img>
                    <div className='description-box'>
                        <div className='sub-desc-box'>
                            <div className='sub-description'>{lang.nftIntro.rent_cost_title}</div>
                            <div className='explain'>{lang.nftIntro.rent_cost_desc}</div>
                        </div>
                        <div className='sub-desc-box'>
                            <div className='sub-description'>{lang.nftIntro.operation_cost_title}</div>
                            <div className='explain'>{lang.nftIntro.operation_cost_desc}</div>
                        </div>
                    </div>
                </div>
                <div className='last-phrase'>{lang.nftIntro.explain_4}</div>
            </div>
            <div className='foot_box'>
                <div className='text-box'>
                    <div className='title'>{lang.nftIntro.title_foot}</div>
                    <div className='description'>{lang.nftIntro.description_foot}</div>
                </div>
                <button className='btn-detail'>{lang.nftIntro.btn_foot}</button>
            </div>
        </div>
    )
}

export default observer(Nftrental);