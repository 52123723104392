const Type = {
    'error':{
        'color':'red'
    },
    'success':{
        'color':'#fff'
    },
    'warning':{
        'color':'#F8AE38'
    }
};
const idName = 'alert';
const Alert = (type,text) => {
    const dom = document.querySelector('.App');
    const pickType = Type.hasOwnProperty(type)?type:'success';
    const alert = document.createElement("div");
    alert.id = idName;
    alert.innerText = text;
    alert.style.position = 'fixed';
    alert.style.width = 'min(300px,80%)';
    alert.style.top = "50%";
    alert.style.left = "50%";
    alert.style.transform = 'translate(-50%,-50%)';
    alert.style.backgroundColor = 'rgba( 0, 0, 0, 0.9 )';
    alert.style.padding = '15px';
    alert.style.fontWeight = '900';
    alert.style.fontSize = 'max(11px,2vmin)';
    alert.style.borderRadius = '1vmin';
    alert.style.zIndex = '9999';
    alert.style.color = Type[pickType]['color'];

    alert.addEventListener('click',AlertCloseEvent);

    const alertBackground = document.createElement("div");
    alertBackground.id = idName+"Background";
    alertBackground.style.position = 'fixed';
    alertBackground.style.top = 0;
    alertBackground.style.left = 0;
    alertBackground.style.width = '100%';
    alertBackground.style.height = '100%';
    alertBackground.style.zIndex = '9998';
    alertBackground.addEventListener('click',AlertCloseEvent);

    AlertCloseEvent();

    alert.dataset.type = pickType;
    dom.appendChild(alert);
    dom.appendChild(alertBackground);
}

const AlertCloseEvent = () => {
    let alertDom = document.querySelector('#'+idName);
    let alertBackgroundDom = document.querySelector('#' + idName + "Background");
    alertDom && alertDom.remove();
    alertBackgroundDom && alertBackgroundDom.remove();
}
export {AlertCloseEvent};
export default Alert;