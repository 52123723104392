const Text = {
    layer_list: [
        {code: 'login', title: '로그인'},
        {code: 'signIn', title: '로그인'},
        {code: 'signUp', title: '회원가입'},
        {code: 'profile', title: '내 프로필'}
    ],
    login_content: "저희 회사는 NFT 게임,\n게임 머니 교환 및 BCL 채굴 앱을 운영합니다.\n이를 통합하여 제공합니다.\n참고하시어 회원가입해주세요.\n회원 정보를 변경하시면,\n전체적으로 변경됩니다.\n참고 부탁드립니다.",
    signin_title: "공지사항",
    signin_content: "저희 회사는 게임,\n화폐 교환 및 BCL 채굴 앱을 운영합니다.",
}

export default Text;