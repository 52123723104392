const Text = {
  benefit: "Benefit",
  title_head: "I know NFT is good,\nbut what are the benefits?",
  description_head: 'There are various benefits such as NFT rental and referral.\nTake advantage of the revenue you want.',
  title_2: "Let's rent the NFT\nYou get benefits and recommendations!",
  class_benefit: 'Grade\nBenefit',
  description_2_1: 'NFTs offer discrimination based on class-specific rarity\nBenefits for both renters renting NFTs and gamers renting',
  description_2_2: 'Categorized into four stages, mining speed, G.Point ratio,\nNFT random delivery, etc.',
  description_2_3: `You can play games or rent them! how let's choose and benefit!`,
  title_3: 'NFT Rental Service',
  subtitle_3_1: 'NFT Grade',
  subtitle_3_2: 'Benefit',
  description_title_3_1: `Rare by Grade`,
  explain_3_1: `*Rarity Criteria %`,
  description_title_3_2: `NFT Owner's Benefits`,
  cell_3_1: `Type of\nBenefit`,
  cell_3_2: `Grade`,
  cell_3_3: `Gamer`,
  cell_3_4: `Lender`,
  cell_3_5: `NFT Airdrop`,
  description_title_3_3: `Benefit both NFT Lenders and Gamers`,
  description_3_3_1: `Render lends NFT to Gamers 2%\n8% of gamers rent NFTs Benefit\neveryone by paying simultaneously.`,
  explain_3_3: '*Common Criteria',
  description_title_3_4: `How to Benefit`,
  choice_a_desc: `You can play the game with your own possession Receive a G.P.`,
  choice_a_desc_2: `212,400G\nGet Benefit`,
  choice_b_desc: `I'll get a rental fee from\na gamer lend it\nto get a G.P.`,
  choice_b_desc_2: `Receive\nRental Fee`,
  choice_b_desc_3: `Receive\nG.Point`,
  title_4: 'Rental rate of profit',
  subtitle_4: `Rental profit table`,
  subtitle_4_1: 'A Comparison of Earnings from Playing a Game and Earnings from Renting an NFTs',
  subtitle_4_2: 'Rental costs based on rarity',
  profit: `Profit`,
  rental_cost: `Rental\nCost`,
  title_4_2: 'NFT Rental Cost System',
  subtitle_4_4: '10,000G.P (NFT Rental Cost / 30D Criteria)',
  description_4_4_1: 'If a gamer pays 10,000G.P for rent, You can get\n132,000G per month on an eight-hour daily basis\n',
  description_4_4_2: 'Gamers pay 10,000 G.P',
  operation_cost_title: `4,000G.P(Operation Cost)`,
  operation_cost_desc: `If you extend it for another month\nAs a long-term benefit, 4,000G.P.\nwill be paid back to the gamer\non a fixed date.`,
  rent_cost_title: `6,000G.P(Rental Cost)`,
  rent_cost_desc: `Get 6,000 G.P.s from gamers\nand rent them for 30 days.`,
  explain_4: '*The benefit settlement will be calculated 30 days from the date of application\nand will be paid on the 25th of next month.',
  title_foot: 'NFT',
  description_foot: `My own Metaverse, let's start easy and fun!`,
  btn_foot: 'Read More',

  rate : 'rate',
  benefit_type : 'Benefits Type',
  classification: 'Classification',
  basicProfit: '8-hour Game Basic Profit',
  pointReward: 'G.Point Reward Rate',
  pointSwap: 'G.Point Conversion',
  pointBenefit: '8-hour Game Basic Benefit',
  pointTotalBenefit: '1-month Game Total Benefit',

}

export default Text;
