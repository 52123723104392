import React from 'react';

import Game from './game';
import GamePick from './gamePick';
import Shopping from './shopping';
import Minning from './minning';

const BCLusage = {
    'game' : <Game />,
    'gamePick' : <GamePick />,
    'shopping' : <Shopping />,
    'minning' : <Minning />
}

export default BCLusage;