import header from './en/header';
import layer from './en/layer';
import alert from './en/alert';
import main from './en/main';
import wallet from './en/wallet';
import contact from './en/contact';
import bclusage from './en/bclusage';
import service from './en/service';
import selectGame from './en/selectGame';
import nftIntro from './en/nftintro';

const en = {
    header : header,
    layer : layer,
    alert : alert,
    main : main,
    wallet : wallet,
    contact : contact,
    bclusage : bclusage,
    service : service,
    selectGame : selectGame,
    nftIntro : nftIntro
}

export default en;
