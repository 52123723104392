const Text = {
    coming_soon : "قريباً",
    preparing : "يتم الإعداد",
    try : 'يرجى المحاولة مرة أخرى في وقت لاحق',
    wait : 'يرجى الانتظار لحظة',
    password_error : 'كلمة المرور غير صحيحة.',
    password_different : 'كلمات المرور مختلفة.',
    password_enter : 'يرجى إدخال كلمة المرور الخاصة بك',
    platform_id_exists : 'تم تسجيل هذا البريد الإلكتروني مسبقاً. يرجى تغيير طريقة تسجيل الدخول',
    different_login : "جرب تسجيل الدخول بطريقة مختلفة.",
    different_email : 'معرف البريد الإلكتروني موجود. يرجى المحاولة باستخدام تسجيل الدخول هذا',
    different_google : "معرف Google موجود. يرجى المحاولة باستخدام تسجيل الدخول هذا",
    different_apple : "معرف Apple موجود. يرجى المحاولة باستخدام تسجيل الدخول هذا",
    app_signup : 'يمكنك التسجيل كعضو من خلال التطبيق.',
    id_check_error : 'خطأ في فحص ID',
    email_code_check : 'يرجى التحقق من بريدك الإلكتروني وإدخال الرمز',
    email_format : 'يجب أن يكون المعرف في تنسيق البريد الإلكتروني',
    email_format2 : 'يجب أن يكون البريد الإلكتروني في تنسيق البريد الإلكتروني',
    email_send_failed : 'فشل إرسال البريد الإلكتروني',
    email_success : 'إرسال كامل!\nشكرا لاهتمامك!',
    email_try : 'يرجى ملء جميع البيانات.\nوحاول مرة أخرى!',
    no_login : 'الدخول مطلوب.',
    no_member : 'لا توجد معلومات عضو.',
    no_password : 'يرجى إدخال كلمة المرور.',
    no_rate : 'هناك مشكلة في الخادم.',
    no_check : 'يرجى التحقق',
    no_gpoint : 'يجب أن تكون نقاط G أكبر من الصفر.',
    no_balance : 'الرصيد الخاص بك غير كافٍ.',
    no_percentage : 'نرجو تحديد النسبة المئوية',
    no_game_data : 'لا توجد بيانات اللعبة. الرجاء التقدم بطلب بعد اللعب.',
    not_code : 'رمزك خاطئ.',
    not_email_format : 'ليس بتنسيق البريد الإلكتروني.',
    duplication_nick : 'تكرار لقبك.',
    char_limit : 'هناك حد لعدد الحروف.',
    connect_wallet : 'الاتصال بمحفظة إلكترونية مطلوب.',
    change_binance : 'أو تغيير الشبكة إلى Binance Chain',
    min_val : 'أدنى مبلغ للصرف هو',
    max_val : 'رقم أعلى من الرصيد.',
    gas_success : 'تم استكمال عملية الغاز. الوقت اللازم للشحن يتطلب وقتًا بسبب وقت المعاملة.',
    payment_success : 'تم استكمال عملية الدفع. الوقت اللازم للشحن يتطلب وقتًا بسبب وقت المعاملة.',
    payment_success2 : 'تم استكمال عملية الدفع.',
    payment_failed : 'هناك مشكلة في عملية المعاملة الخاصة بك. يرجى المحاولة مرة أخرى',
    payment_bcl : 'يمكن إجراء الدفع من خلال تطبيق BCL.',
    payment_after : 'تم معالجة المعاملة بالفعل. يرجى المحاولة مرة أخرى بعد التحديث',
    payment_one_day :  "يمكن إرسال المبالغ مرة واحدة فقط في اليوم.",
}

export default Text;