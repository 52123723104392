import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import defaultImg from '../assets/temp.png';

// Styled-Component 라이브러리를 활용해 TabMenu 와 Desc 컴포넌트의 CSS를 구현.

const TabMenu = styled.div`
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  .submenu {
    background-color: #898989;
    width: calc(100% /2);
    padding: 2vmin;
    font-size: 2vmin;
    border-radius: 10px 10px 0px 0px;
  }

  .focused {
    background-color: #F69032;
    color: #FFFFFF;
  }
`;

const PresentBox = styled.div`
  position: relative;
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 2px #F69032 inset;
  padding-top: 2vmin;
  padding-bottom: 2vmin;
`;

const Title = styled.div`
  position: relative;
  font-size: 3.4vmin;
  color: #F69032;
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 3vmin;
  text-align: left;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
`;

const PresentText = styled.div`
  position: relative;
  margin-left: 2vmin;
  width: 70%;
  text-align: left;
`;

const Desc = styled.div`
  position: relative;
  display: flex;
`;

const DetailBox = styled.div`
  position: relative;
  width: 40%;
`;

const Detail = styled.div`
  position: relative;
  font-size: 3vmin;
  transform: translate(-15%, -16%) scale(0.7);
  width: 100%;
  margin-right: -5vmin;
  display: inline-block;
  white-space : nowrap;
`;

const DetailBox2 = styled.div`
  position: relative;
  width: 60%;
`;

const Detail2 = styled.div`
  position: relative;
  font-size: 3vmin;
  transform: translate(-15%, -16%) scale(0.5);
  width: 100%;
  display: inline-block;
  white-space : nowrap;
`;

const PresentImgBox = styled.div`
  position: relative;
  height: min(36vmin, 21vmax);
  margin-left: 1vmin;
  background-color: #29263A;
  width: 40%;
  border-radius: 2vmin;
`;

const PresentImgDesc = styled.div`
  color: #F69032;
  font-size: 2vmin;
  font-wight: bold;
  text-align: left;
  margin-left: 10%;
`;

const PresentImg = styled.img`
  position: relative;
  width: 90%;
  height: 80%;
  margin-top: 2vmin;
  border-radius: 2vmin;
`;

const HistoryCell = styled.div`
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const Cell = styled.div`
  position: relative;
  font-size: 3.5vmin;
  width: 100%;
  text-align: center;
  display: inline-block;
`;


const BASE_URL = 'https://maxform.link/api/exchange/nft'

const Tab = () => {
  // Tab Menu 중 현재 어떤 Tab이 선택되어 있는지 확인하기 위한 currentTab 상태와 currentTab을 갱신하는 함수가 존재해야 하고, 초기값은 0.
  const [currentTab, clickTab] = useState(0);
  const [present, setPresent] = useState([]);
  const [history, setHistory] = useState([]);

  const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
  const walletConnect = window.localStorage.getItem("wagmi.connected");
  const user = JSON.parse(window.sessionStorage.getItem('user'));

  const menuArr = [
    { name: 'Present', content: present },
    { name: 'History', content: history },
  ];

  const selectMenuHandler = (index) => {
    clickTab(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user && walletAccount) {
        let response = await axios.get(
          // BASE_URL + `/rent-present.php?email=test123@test.com&account=0xtestaccount`
          BASE_URL + `/rent-present.php?email=${user.key}&account=${walletAccount.state.data.account}`
        );
        console.log(response.data)
        setPresent(response.data);
  
        response = await axios.get(
          // BASE_URL + '/rent-history.php?email=test123@test.com&account=0xtestaccount'
          BASE_URL + `/rent-history.php?email=${user.key}&account=${walletAccount.state.data.account}`
        );
        setHistory(response.data);
      } else {}
    };

    fetchData();
  }, []);

  return (
      <div>
        <TabMenu>
          {menuArr.map((el,index) => (
              <div className={index === currentTab ? "submenu focused" : "submenu" }
              onClick={() => selectMenuHandler(index)}>{el.name}</div>
            ))}
        </TabMenu>
        { currentTab == '0' ?
          <PresentBox>
            <PresentImgBox>
              <PresentImg src={(present.length > 0) ? '../../img/nft/' + (parseInt(present[0].token_id, 10) +1).toString() +'.png' : defaultImg}/>
              <PresentImgDesc>
                MaxForm #{(present.length > 0) ? present[0].token_id : ''}
              </PresentImgDesc>
            </PresentImgBox>
            <PresentText>
              <Title>Benefits in progress</Title>
              <SubTitle>Maxform #{(present.length > 0) ? present[0].token_id : ''}</SubTitle>
              <Desc>
                <DetailBox>
                  <Detail>Rarity Rank</Detail>
                  <Detail>Rarity Rating</Detail>
                  <Detail>State</Detail>
                  <Detail>Rental period</Detail>
                  <Detail>Settlement date</Detail>
                </DetailBox>
                <DetailBox2>
                  <Detail2>{(present.length >0) ? present[0].token_id : ''}</Detail2>
                  <Detail2>{(present.length >0) ? present[0].rarity : ''}</Detail2>
                  <Detail2>{(present.length >0) ? present[0].bonus_p : ''}</Detail2>
                  <Detail2>{(present.length >0) ? present[0].startedAt : ''} ~ {(present.length >0) ? (present[0].endAt).split(' ')[0] : ''}</Detail2>
                  <Detail2>{(present.length >0) ? present[0].payday : ''}</Detail2>
                </DetailBox2>
              </Desc>
            </PresentText>
          </PresentBox>
          :
          <PresentBox>
              <HistoryCell>
                {history.map((item, index) => (
                  <Row>
                    <Cell>G.point payment</Cell>
                    <Cell>{item.settledAt.substring(0, 10)}</Cell>
                  </Row>
                ))}
              </HistoryCell>
          </PresentBox>
        }
      </div>
  );
};

export default Tab;