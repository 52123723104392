import React,{useState} from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import Alert from '../../utils/alert';
import lang from '../../lang/lang';
const Sell = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const serviceCode = sessionStorage.getItem("serviceCode");
    const rate = JSON.parse(sessionStorage.getItem("rate"));
    
    let rateMinAccount = ''; 
    let rateVal = '';
    if(rate)
    {
        for(var key in rate) {
            if(rate[key]['code'] === 'bcl' && (rate[key]['type'] === null || rate[key]['type'] === 'bcl'))
            {
                rateMinAccount = 1000;
                rateVal = rate[key]['rate'];
            }
        }
    }

    const [input, setInput] = useState({
        bcl : 0,
        gpoint : 0,
        max : (userData)?userData['gpoint']['amount']:0,
        ratio : rateVal,
        min: rateMinAccount
    });
    const {bcl,gpoint,max,ratio,min} = input;

    if(!user){
        Alert('error',lang.alert.no_login);
        return;
    }
    if(!rate){
        alert(lang.alert.no_rate);
        window.location.replace('/');
        return;
    }

    const changeInput = (e) => {
        const {value} = e.target;
        const removedCommaValue = Number(value.replaceAll(",", "")) ? Number(value.replaceAll(",", "")) : 0 ;
        
        if(removedCommaValue > max){
            Alert('error',lang.alert.max_val+'\n max G.Point : '+max);
            return;
        }

        setInput((prevState)=>{return {...prevState,gpoint:removedCommaValue}});  

        const changeBcl = Math.floor(removedCommaValue * ratio / 1000);
        setInput((prevState)=>{return {...prevState,bcl:changeBcl}});

    };
    const submit = () => {
        if(!gpoint){
            Alert('error',lang.alert.no_gpoint);
            return;
        }
        if(Number(gpoint) < Number(min)){
            Alert('error',lang.alert.min_val+' '+min.toLocaleString()+'.');
            return;
        }
        axios.get(`https://maxform.link/api/exchange/fbcjfrl/point2bcl.php?email=${user['key']}&gameName=${serviceCode}&amount=${gpoint}&type=gpoint`)
        .then((res) => {
            if(res.data === 'success'){
                axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${user['key']}`)
                .then((res) => {
                    if(res.data){
                        window.sessionStorage.removeItem('userData');
                        window.sessionStorage.setItem("userData", JSON.stringify(res.data));
                        alert(lang.alert.payment_success2);
                    }
                    window.location.replace('/service');
                })
                .catch((err) => {
                    Alert('warning',lang.alert.try);
                })
            }else{
                Alert('error',lang.alert.payment_failed);
            }
        })
        .catch((err) => {
            Alert('warning',lang.alert.try);
        })
    };
    return (
        <div className='sell'>
            <div className='btnType1 gpoint'>
                <input name='gpoint' value={gpoint.toLocaleString()}  onChange={changeInput}/>
                <button>G.point</button>
            </div>
            <div className='sell_buy'>
                <div className='border'></div>
                <div className='line'>
                    <div className='sign'></div>
                    <div className='text'>min G.Point : {Number(min).toLocaleString()} BCL</div>
                </div>
                <div className='line'>
                    <div className='sign'></div>
                    <div className='text'>max G.Point : {Number(max).toLocaleString()} BCL</div>
                </div>
                <div className='border'></div>
                <div className='line'>
                    <div className='sign'>
                    </div>
                    <div className='text'>G.Point : {gpoint.toLocaleString()} G.Point</div>
                </div>
                <div className='line'>
                    <div className='sign'>
                        <img src={`${process.env.PUBLIC_URL}img/icon/multiply_fff.png`}  alt=''/>
                    </div>
                    <div className='text'>Ratio : ≒ {ratio/1000}%</div>
                </div>
                <div className='border'></div>
                <div className='line'>
                    <div className='sign'>
                        <img src={`${process.env.PUBLIC_URL}img/icon/equalSign_fff.png`}  alt=''/>
                    </div>
                    <div className='text'>≒ {bcl.toLocaleString()} Total BCL</div>
                </div>
                <div className='border'></div>
            </div>
            <div className='btnType1 bcl'>
                <input name='bcl' value={bcl.toLocaleString()} onChange={changeInput} disabled/>
                <button>BCL</button>
            </div>
            <div className='btnType2'>
                <button onClick={() => submit()}>Start</button>
            </div>
        </div>
    );
}

export default observer(Sell);