import React from 'react';
import { observer } from 'mobx-react';
import lang,{getLangImg,langImgError} from '../../lang/lang';
import '../../css/pages/bclusage/minning.scss';

const Minning = () =>{
    return (
        <div className='minning'>
            <div className='text'>
                <p>{lang.bclusage.ment2}</p>
            </div>
            <div className='img'>
                <img src={`${process.env.PUBLIC_URL}/img/page/bclusage/background_3${getLangImg}.png`} onError={langImgError} alt='' />
            </div>
            <div className='button'>
                <div>
                    <button onClick={()=>{window.location.href='https://play.google.com/store/apps/details?id=com.bitlegendapp'}}>Go to Minning</button>
                </div>
            </div>
        </div>
    );
}

export default observer(Minning);