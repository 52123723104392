import React,{useState} from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import Alert from '../utils/alert';
import lang from '../lang/lang';
import '../css/pages/contact.scss';



const Contact = () => {
    const [input, setInput] = useState({
        name : '',
        email : '',
        phone : '',
        url : '',
        category : '',
        content : ''
    });
    const {name,email,phone,url,category,content} = input;
    const changeInput = (e) => {
        const val = e.target.value;
        setInput({
            ...input,
            [e.target.name]:val
        });
    }
    const resetInput = () => {
        setInput({
            name : '',
            email : '',
            phone : '',
            url : '',
            category : '',
            content : ''
        });
    }

    const sending = () => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');

        if(name && email && phone && url && content)
        {
            if(Buffer.byteLength(name) >= 100 || Buffer.byteLength(phone) >= 100 || Buffer.byteLength(email) >= 50)
            {
                Alert('warning',lang.alert.char_limit+'\n Name : ' + Buffer.byteLength(name) + '/100 \n Contact Number : ' + Buffer.byteLength(phone) + '/100 \n E-mail : ' + Buffer.byteLength(email) + '/50');
            }
            else
            {
                if(regex.test(email))
                {
                    axios.get(`https://maxform.link/api/exchange/sendContact.php?name=${name}&email=${email}&phone=${phone}&url=${url}&category=${category}&content=${content}`)
                    .then((res) => {
                        if(res.data === 'success')
                        {
                            Alert('success',lang.alert.email_success);
                            resetInput();
                        }else
                        {
                            Alert('error',lang.alert.email_send_failed);
                        }
                    })
                    .catch((err) => {
                        Alert('warning',lang.alert.try);
                    })
                }
                else
                {
                    Alert('error',lang.alert.email_format2);
                }
            }
        }
        else
        {
            Alert('error',lang.alert.email_try);
        }
    }
    return (
        <div className='contact'>
            <div className='title type1'>
                <p>Contact</p>
            </div>
            <div className='content_box'>
                <div className='title'>
                    <p>{lang.contact.title}</p>
                </div>
                <div className='myEmail'>
                    <p>help@maxform.io</p>
                </div>
                <div className='explanation'>{lang.contact.explanation}</div>
                <div className='input_box'>
                    <div>
                        <p>Name</p>
                        <input name='name' onChange={changeInput} value={name}/>
                    </div>
                    <div>
                        <p>E-mail</p>
                        <input name='email' onChange={changeInput} value={email} placeholder={lang.contact.placeholder_email}/>
                    </div>
                    <div>
                        <p>Contact Number</p>
                        <input name='phone' onChange={changeInput} value={phone} placeholder={lang.contact.placeholder_number}/>
                    </div>
                    <div>
                        <p>Do you have a website?</p>
                        <input name='url' onChange={changeInput} value={url} placeholder={lang.contact.placeholder_website}/>
                    </div>
                    <div>
                        <p>What are you Intersted In?</p>
                        <select name='category' onChange={changeInput}>
                            <option value='blockchain'>Block Chain</option>
                            <option value='nft'>NFT</option>
                            <option value='miningapp'>Mining App</option>
                            <option value='exchangepage'>Exchange Page</option>
                            <option value='etc'>ETC</option>
                        </select>
                    </div>
                    <div>
                        <p>Message</p>
                        <textarea name='content' onChange={changeInput} value={content}></textarea>
                    </div>
                </div>
            </div>
            <div className='button'>
                <button onClick={()=>sending()}>Sending</button>
            </div>
        </div>
    );
}
export default observer(Contact);