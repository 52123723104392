import React from "react";
import { Link  } from 'react-router-dom';
import '../css/utils/slide.scss';

const className = 'utils_slide_div';
const classNameRandom = 'slide'+Math.floor(Math.random() * 100);
const classArr = [className,classNameRandom];
let slideOnOff = false;
let startPoint = 0;
let movePoint = 0;
let endPoint = 0;
let lineLeft = 0;
let childNum = 0;

const slideStart = (e) =>{
    if(!slideOnOff)
    {
        slideOnOff = true;
        startPoint = (e.pageX)?e.pageX:e.touches[0].pageX;
    }
}

const slideMove = (e) => {
    if(slideOnOff)
    {
        movePoint = (e.pageX)?e.pageX:e.touches[0].pageX;
        let moveValue = movePoint - startPoint;
        startPoint = movePoint;
        dragSlideEvent(moveValue);  
    }
}

const slideEnd = (e) =>{
    endPoint = (e.pageX)?e.pageX:e.touches[0].pageX;
    let moveValue = endPoint - startPoint;
    slideOnOff = false;
    Math.abs(moveValue) >= 50 && dragSlideEvent(moveValue);
}

const dragSlideEvent = (i) => {
    i=i/2;
    const parent = document.querySelector('.'+className+'.'+classNameRandom+' .slide');
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .line');
    const child = dom.children;

    let move = lineLeft + i;
    if(move >= 0)
    {
        dom.style.left = 0;
        lineLeft = 0;
        childNum = 0;
    }
    else if(dom.offsetWidth - parent.offsetWidth <= -move)
    {
        dom.style.left = -(dom.offsetWidth - parent.offsetWidth) + "px";
        lineLeft = -(dom.offsetWidth - parent.offsetWidth);
        childNum = child.length - 1;
    }
    else
    {
        dom.style.left = move +"px";
        lineLeft = move;
        childNum = Math.round(-move / child[0].offsetWidth);
    }
    display(childNum);
}

const buttonSlideEvent = (b) => {
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .line');
    const child = dom.children;
    b = (b)?1:-1;
    childNum = (childNum + b >= 0 && childNum + b < child.length) && childNum + b;
    display(childNum);
    buttonMove(childNum);
}

const buttonNavEvent = (i) => {
    childNum = i;
    display(i);
    buttonMove(i);
}
const buttonMove = (i) => {
    const parent = document.querySelector('.'+className+'.'+classNameRandom+' .slide');
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .line');
    const child = dom.children;
    let move = ((dom.offsetWidth - parent.offsetWidth) < (i * child[0].offsetWidth))
    ?dom.offsetWidth - parent.offsetWidth
    :i * child[0].offsetWidth;

    dom.style.left = -move + "px";
    lineLeft = -move;
}
const display = (i) => {
    i = (i)?i:0;
    slideDisplay(i);
    buttonDisplay(i);
    navDisplay(i);
}

const slideDisplay = (i) => {
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .line');
    const child = dom.children;
    for(var j=0;j<child.length;j++){
        child[j].querySelector('.img').classList.remove('action');
        child[j].querySelector('.name').classList.remove('action');
    }
    child[i].querySelector('.click').click();
    child[i].querySelector('.img').classList.add('action');
    child[i].querySelector('.name').classList.add('action');
}

const buttonDisplay = (i) => {
    const parent = document.querySelector('.'+className+'.'+classNameRandom+' .slide');
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .line');
    const child = dom.children;
    parent.querySelector('.left').style.display = (i)
    ?'block'
    :'none';
    parent.querySelector('.right').style.display = (dom.offsetWidth < (i * child[0].offsetWidth))
    ?'none'
    :'block';
}

const navDisplay = (i) => {
    const dom = document.querySelector('.'+className+'.'+classNameRandom+' .nav');
    const child = dom.children;
    for(var j=0;j<child.length;j++){
        dom.children[j].classList.remove('action');
    }
    dom.children[i].classList.add('action');
}

const Silde = (data) => {
    return (
        <div className={classArr.join(" ")}>
            <div className='slide'>
                <div className="line" 
                     onTouchStart={slideStart}
                     onTouchMove={slideEnd}
                     onMouseUp={slideEnd}
                     onMouseMove={slideMove}
                     onMouseDown={slideStart}>
                {
                data.map((i,j)=>{
                    var pick = (j===0)?'action':'';
                    return (
                    <div key={j}>
                        <Link to={i.link} onClick={()=>display(j)}>
                        <div className={`img ${pick}`}><img src={`${process.env.PUBLIC_URL}${i.img}`} alt=''/></div>
                        <div className={`name ${pick}`}>{i.name}</div>
                        <div className="click" onClick={()=>{i.click(i.code)}}></div>
                        </Link>
                    </div>
                    );
                })
                }
                </div>
                <div className="button left">
                    <button onClick={()=>buttonSlideEvent(false)}><img src={`${process.env.PUBLIC_URL}/img/icon/left_1.png`} alt=''/></button>
                </div>
                <div className="button right">
                    <button onClick={()=>buttonSlideEvent(true)}><img src={`${process.env.PUBLIC_URL}/img/icon/right_1.png`} alt=''/></button>
                </div>
            </div>
            <div className='nav'>
            {
                data.map((i,j)=>{
                    var pick = (j===0)?'action':'';
                    return (
                    <div className={pick} key={j} onClick={()=>buttonNavEvent(j)}></div>
                    );
                })
            }
            </div>
        </div>
    );
    
}
export default Silde; 