import axios from 'axios';
import qs from "qs";
import lang from '../lang/lang';
import Alert from '../utils/alert';


const CLIENT_ID =
  "944783334339-tfd4skolf4p2jb7bkv696laemq5q8fib.apps.googleusercontent.com";
const AUTHORIZE_URI = "https://accounts.google.com/o/oauth2/v2/auth";
const PEOPLE_URI = "https://www.googleapis.com/userinfo/v2/me";

const queryStr = qs.stringify({
  client_id: CLIENT_ID,
  redirect_uri: window.location.protocol + "//" + window.location.host+"/google",
  response_type: "token",
  scope: "https://www.googleapis.com/auth/userinfo.email"
});
const loginUrl = AUTHORIZE_URI + "?" + queryStr;

export default () => {
    const { access_token } = qs.parse(window.location.hash.substr(1));
    if (!access_token) {
      window.location.assign(loginUrl);
      return null;
    }

    const loadMyAccount = (id) => {
      axios.get(`https://maxform.link/api/exchange/LoadMyAccount.php?Email=${id}`)
      .then((res) => {
          if(res.data){
              window.sessionStorage.setItem("userData", JSON.stringify(res.data));
            }else{
              window.sessionStorage.removeItem('user');
              Alert('warning',lang.alert.try);
            }
            window.location.replace('/');
      })
      .catch((err) => {
          window.sessionStorage.removeItem('user');
          Alert('warning',lang.alert.try);
      })
  }

   axios.get(`${PEOPLE_URI}?access_token="${access_token}"`)
   .then((res) => {
          const res_name = res.data.email.split('@')[0];
          const res_id = res.data.id;
          axios.get(`https://maxform.link/api/exchange/LoginGoogle.php?Email=${res.data.email}&Name=${res_name}&ID=${res_id}&Photo=${res.data.picture}`)
           .then((res) => {
            if(res.data === 'failed')
            {
                alert(lang.alert.no_member);
                window.location.replace('/');
            }
            else if(res.data === 'nobcl')
            {
                alert(lang.alert.app_signup);
                window.location.replace('/');
            }
            else if(res.data === 'check_provider')
            {
                alert(lang.alert.different_login);
                window.location.replace('/');
            }
            else
            {
                window.sessionStorage.setItem("user", JSON.stringify(res.data));
                loadMyAccount(res.data.key);
            }
           })
           .catch((err) => {
              window.sessionStorage.removeItem('user');
              Alert('warning',lang.alert.try);
           })
   })
   .catch((err) => {
       alert(lang.alert.try)
   })

};