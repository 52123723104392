const Text = {
    title : "개인정보",
    welcome : "환영합니다!",
    content1 : "Metamask가 연결되어 있어야 합니다.",
    content2 : "네트워크를 BNB로 변경해주세요.",
    content3 : "웹 결제는 Metamask 확장 프로그램을 이용합니다.",
    content4 : "모바일 결제는 마이닝 앱에서 이용 가능합니다.",
    confirm : '확인',
    next : '다음으로 가기',
    }

export default Text;