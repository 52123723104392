import en from './en';
import ar from './ar';
import ko from './ko';
import vi from './vi';
import zh from './zh';
import LocalizedStrings from 'react-localization';
let langType = ['ar','en','ko','vi','zh'];
let lang = new LocalizedStrings({ en ,ar, ko , vi , zh});
let getLang , getLangImg , getArClass;
try{
    getLang = window.localStorage.getItem("GlobalState");
    if(!getLang){
        window.localStorage.setItem("GlobalState",'en');
        getLang = 'en';
    }
    lang.setLanguage(getLang);
} catch (e) {
    getLang = 'en';
    lang.setLanguage("en");
}
getLangImg = (getLang)?"_"+getLang:"";
getArClass = "";

const langImgError = (e) => {
    const arr = e.target.src.split(".");
    const imgLastName = arr[arr.length - 2].slice(-3);
    let newLink = '';
    for(var i=0;i<langType.length;i++)
        arr[arr.length - 2] = imgLastName === "_"+langType[i] ?arr[arr.length - 2].slice(0, arr[arr.length - 2].length - 3) :arr[arr.length - 2];
    for(var i=0;i<arr.length;i++)
        newLink += arr[i]+".";
    newLink = newLink.slice(0,newLink.length-1);
    e.target.src = newLink;
}

export {getLang,getLangImg,langType,langImgError};
export default lang;