import React from 'react';
import { observer } from 'mobx-react';
import Layer from '../../config/layer';
import Alert from '../../utils/alert';
import lang from '../../lang/lang';
import '../../css/pages/layer/common/type1.scss';
import '../../css/pages/layer/profile.scss';
const logout = () => {
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('userData');
    window.location.replace('/');
}
const ProfileImg = () => {
    Alert('warning',lang.alert.preparing);
}

const Profile = () =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if(!user || !userData){ 
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('userData');
        window.location.replace('/')
    };

    const ImgError = (e) => {
        e.target.src = `${process.env.PUBLIC_URL}/img/button/picture_1.png`;
    }
    const profileImg = user['profile_image_url'] ?user['profile_image_url']:`${process.env.PUBLIC_URL}/img/button/picture_1.png`;
    return (
        <div className='profile'>
            <div className='content_box'>
                <div className='title'>
                    <p>MY PROFILE</p>
                    <img className='simple' src={`${process.env.PUBLIC_URL}/img/page/layer/profile/line_1.png`} alt=''/>
                </div>
                <div className='user'>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/img/page/layer/profile/background_1.png`} alt=''/>
                    </div>
                    <div>
                        <div className='simple'>
                            <button onClick={()=>ProfileImg()}>
                                <img src={profileImg} onError={ImgError} alt=''/>
                            </button>
                        </div>
                        <div>
                            <p>{user.key}</p>
                        </div>
                        <div>
                            <div>
                                <p className='line'>BCL</p>
                                <p>{Number(userData.bcl.amount).toLocaleString()}</p>
                            </div>
                            <div>
                                <p className='line'>G.Point</p>
                                <p>{Number(userData.gpoint.amount).toLocaleString()}</p>
                            </div>
                            <div>
                                <p className='line'>Gas Fee</p>
                                <p>{Number(userData.gas.amount).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info'>
                    <table id='profileTable'>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Diamond</th>
                                <th>Gold</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userData.game.map((i,j)=>{
                                    return (
                                        <tr key={j}>
                                            <td>{i.name}</td>
                                            <td>{i.UDIAMONDS?i.UDIAMONDS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):0}</td>
                                            <td>{i.UCOINS ?i.UCOINS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):0}</td>
                                        </tr>
                                    );
                                })
                            }
                            <tr>
                                <td>Soon</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='btn'>
                    {/* <button onClick={()=>profileImg()}><img src={`${process.env.PUBLIC_URL}/img/button/btn_background_1.png`} alt=''/><p>CONTINUE</p></button> */}
                    <button onClick={()=>logout()}><img src={`${process.env.PUBLIC_URL}/img/button/btn_background_1.png`} alt=''/><p>LOG OUT</p></button>
                </div>
            </div>
            <div className='back_box' onClick={()=>Layer('profile')}><img src={`${process.env.PUBLIC_URL}/img/page/layer/background_1.png`} alt=''/></div>
        </div>
    );
}

export default observer(Profile);