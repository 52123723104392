import header from './vi/header';
import layer from './vi/layer';
import alert from './vi/alert';
import main from './vi/main';
import wallet from './vi/wallet';
import contact from './vi/contact';
import bclusage from './vi/bclusage';
import service from './vi/service';
import selectGame from './vi/selectGame';
import nftIntro from './vi/nftintro';

const vi = {
    header : header,
    layer : layer,
    alert : alert,
    main : main,
    wallet : wallet,
    contact : contact,
    bclusage : bclusage,
    service : service,
    selectGame : selectGame,
    nftIntro : nftIntro
}

export default vi;