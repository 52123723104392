import React from 'react';
import {  Route, Routes, useNavigate  } from 'react-router-dom';
import { observer } from 'mobx-react';
import Header from '../pages/header/header';
import route from './route';
import googleCallback from '../api/google';
import appleCallback from '../api/apple';


const Print = () => {
    const Navigate = useNavigate();
    const routeMap = (data) => {
        return data.map((val, index) => (
            <Route
                key={index}
                path={val.path}
                Component={
                    val.access !== undefined && val.access === 'public' 
                    ? val.Component 
                    :(()=>Navigate('/'))
                }
            >
            </Route>
        ))
    }
    return (
    <div>
        <Header />
        <Routes>
            {routeMap(route)}
            <Route path='/google' Component={googleCallback}></Route>
            <Route path='/apple' Component={appleCallback}></Route>
        </Routes>        
    </div>
    );
};
export default observer(Print);