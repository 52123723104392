import React from 'react';
import { observer } from 'mobx-react';
import { Link  } from 'react-router-dom';
import menu from '../../config/menu';
import '../../css/pages/header/menu.scss'

const Menu = () =>{
    const LinkMap = (data) => {    
        return data.map(val => (
            <div key={val.code} className='list'>
                <Link to={val.path} onClick={()=>close()}>{val.title}</Link>
                {val.children !== false && LinkMap(val.children)}
            </div>
        ))
    }

    const close = () => {
        const dom = document.querySelector("#header_menu");
        dom.classList.contains('action')
        ? dom.classList.remove('action')
        : dom.classList.add('action')
    }

    return (
    <div className='menu' id='header_menu'>
        <div onClick={() => close()}></div>
        <div>
            <div className='close'>
            <button onClick={() => close()}><img src='/img/icon/close_1.png' alt=""/></button>
            </div>
            {LinkMap(menu.header_menu)}
            <div className='gap'></div>
        </div>    
    </div>
    );
}

export default observer(Menu);